import { Box, Grid, Typography } from "@mui/material";
import {
  BooleanField,
  Button,
  Datagrid,
  DateField,
  Labeled,
  List,
  TextField,
  TopToolbar,
  useRecordContext,
} from "react-admin";
import { useLocation } from "react-router-dom";
import ConditionalPeriodicTypeField from "../Fields/ConditionalPeriodicTypeField";
import CustomLink from "components/commons/fields/CustomLink";
import CustomListButton from "components/commons/buttons/CustomShowButton";

const TaskRowExpand = () => {
  const record = useRecordContext();
  if (!record) return null;

  return (
    <Grid container width={{ xs: "100%", xl: 800 }}>
      <Grid item xs={12} md={12}>
        <Box display={{ xs: "block", sm: "flex" }}>
          <Box flex={2}>
            <Labeled source="valorization_start_date">
              <DateField source="valorization_start_date" fullWidth />
            </Labeled>
          </Box>
          <Box flex={2}>
            <Labeled source="one_off">
              <BooleanField source="one_off" fullWidth />
            </Labeled>
          </Box>
          <Box flex={2}>
            <Labeled source="args">
              <TextField source="args" fullWidth />
            </Labeled>
          </Box>
          <Box flex={2}>
            <Labeled source="kwargs">
              <TextField source="kwargs" fullWidth />
            </Labeled>
          </Box>
          <Box flex={2}>
            <Labeled source="start_time">
              <DateField source="start_time" fullWidth />
            </Labeled>
          </Box>
          <Box flex={2}>
            <Labeled source="expires">
              <DateField source="expires" fullWidth />
            </Labeled>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <Box display={{ xs: "block", sm: "flex" }}>
          <ConditionalPeriodicTypeField />
        </Box>
      </Grid>
    </Grid>
  );
};

type Props = {
  endpoint: string;
};

export default function ConfigPeriodicTasksList({ endpoint }: Props) {
  const { pathname } = useLocation();

  const showResource = `${pathname}/periodic_tasks`;
  const createPath = `${showResource}/create`;
  return (
    <>
      <Typography variant="h5">Periodic tasks</Typography>
      <TopToolbar>
        <CustomLink to={createPath}>
          <Button label="Créer" />
        </CustomLink>
      </TopToolbar>
      <List resource={endpoint} disableSyncWithLocation>
        <Datagrid bulkActionButtons={false} rowClick="expand" expand={<TaskRowExpand />}>
          <TextField source="id" sortable={false} />
          <TextField source="name" sortable={false} />
          <TextField source="task" sortable={false} />
          <BooleanField source="enabled" sortable={false} />
          <CustomListButton link={showResource} />
          <CustomListButton link={showResource} type={"show"} />
        </Datagrid>
      </List>
    </>
  );
}
