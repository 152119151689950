import { Grid, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { Loading, useCreate } from "react-admin";
import { useNavigate, useParams } from "react-router-dom";
import { baseResources } from "config_infos";
import ConfigWebhookBaseForm from "./ConfigWebhookBaseForm";
import CustomAlert from "components/commons/statuses/CustomAlert";

export default function ConfigWebhookCreateForm() {
  const [submissionError, setSubmissionError] = useState([]);
  const { service }: any = useParams();
  const resource = baseResources[service].WEBHOOKS;
  const navigate = useNavigate();
  const [create, { isLoading, error }]: any = useCreate();
  const handleSubmit = useCallback(
    (data: any) => {
      create(
        resource,
        { data },
        {
          onError: (error: any) => {
            setSubmissionError(error.body.detail.map(({ loc, msg }: any) => `${loc[1]} : ${msg}`));
          },
          onSuccess: () => {
            navigate(`/config/${service}`);
          },
        },
      );
    },
    [create, navigate, resource, service],
  );
  if (isLoading) return <Loading />;

  return (
    <Grid container p={2}>
      {submissionError.length > 0 && (
        <Grid item xs={12}>
          <CustomAlert
            title={<strong>{error.message}</strong>}
            subTitle={submissionError?.map((err) => (
              <Typography key={err}>{err}</Typography>
            ))}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <ConfigWebhookBaseForm handleSubmit={handleSubmit} />
      </Grid>
    </Grid>
  );
}
