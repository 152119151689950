import { Grid } from "@mui/material";
import { DateInput, DateTimeInput, Edit, SelectInput, SimpleForm, TextInput } from "react-admin";
import { optimizerCountryChoices, optimizerDirectionChoices, optimizerMarketChoices } from "../opti_enums";

export default function BidSettingsEdit() {
  return (
    <Edit>
      <SimpleForm>
        <Grid container>
          <Grid item xs={4}>
            <SelectInput source="market" choices={optimizerMarketChoices} />
          </Grid>
          <Grid item xs={4}>
            <SelectInput source="country" choices={optimizerCountryChoices} />
          </Grid>
          <Grid item xs={4}>
            <DateTimeInput source="start_date" />
          </Grid>
          <Grid item xs={4}>
            <DateTimeInput source="end_date" />
          </Grid>
          <Grid item xs={4}>
            <DateInput source="delivery_date" />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="min_price" />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="max_price" />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="price" />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="min_quantity" />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="max_quantity" />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="offered_quantity" />
          </Grid>
          <Grid item xs={4}>
            <SelectInput source="direction" choices={optimizerDirectionChoices} />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
}
