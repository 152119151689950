import { addHours, setHours } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { useCallback, useEffect, useMemo } from "react";
import {
  ArrayInput,
  DateInput,
  FormDataConsumer,
  FormDataConsumerRenderParams,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  useNotify,
  useRedirect,
} from "react-admin";
import { FieldValues, SubmitHandler, useFormContext } from "react-hook-form";
import { ecpBidBusinessTypeEnum, EcpBidDirectionEnum, EcpGateWithBidsType } from "../types";
import { ecpBidBusinessTypeChoices, ecpBidDirectionChoices } from "../utils";
import { validateBids } from "./ecpBaseFormValidation";
import { baseResources } from "config_infos";
import axios from "axios";

type GateEditProps = {
  isEdit: boolean;
  defaultValues: EcpGateWithBidsType | null;
};

const EcpGateBaseForm = ({ isEdit, defaultValues }: GateEditProps) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit: SubmitHandler<FieldValues> = useCallback(
    async (values) => {
      const { bids, delivery_date } = values;
      const formattedBids: any[] = [];

      // Convert delivery date to CET/CEST (Europe/Berlin)
      const deliveryDateInCET = zonedTimeToUtc(new Date(delivery_date), "Europe/Berlin");

      bids.forEach((bid: any) => {
        const { start_hour, end_hour, ...rest } = bid;

        // Divide the bid into 1-hour intervals
        for (let hour = start_hour; hour < end_hour; hour++) {
          // Set start and end times within the CET/CEST timezone
          const start_date = setHours(deliveryDateInCET, hour);
          const end_date = addHours(start_date, 1);
          formattedBids.push({
            ...rest,
            start_date: start_date.toISOString(),
            end_date: end_date.toISOString(),
          });
        }
      });

      const data = { delivery_date: deliveryDateInCET.toISOString(), bids: formattedBids };

      try {
        const { status } = await axios.post(
          `${process.env.REACT_APP_ECP_API_URL}/${baseResources.ecp.BIDS}/many`,
          data,
        );

        if (status >= 200 && status < 300) {
          notify("Bids created successfully", { type: "success" });
          redirect("list", baseResources.ecp.GATES);
        }
      } catch (error: any) {
        notify(`Error: ${error.message}`, { type: "error" });
      }
    },
    [notify, redirect],
  );

  return (
    <SimpleForm
      defaultValues={defaultValues}
      validate={validateBids}
      onSubmit={handleSubmit}
      sanitizeEmptyValues
      mode="onBlur"
    >
      <DateInput source="delivery_date" validate={required()} disabled={isEdit} />
      <ArrayInput source="bids">
        <SimpleFormIterator
          inline
          sx={{
            "& .RaSimpleFormIterator-form": {
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
                md: "repeat(3, 1fr)",
              },
              gap: 2,
              p: 2,
              pb: 0,
              borderRadius: 2,
              boxShadow: 4,
              mt: 2,
              ml: 2,
            },
            "& .RaSimpleFormIterator-line": {
              border: "none",
            },
          }}
        >
          <SelectInput source="business_type" choices={ecpBidBusinessTypeChoices} validate={required()} />
          <FormDataConsumer>
            {(formDataConsumerProps: FormDataConsumerRenderParams) => {
              return <EcpSubForm {...formDataConsumerProps} />;
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
};

export default EcpGateBaseForm;

const EcpSubForm = ({ scopedFormData, getSource }: FormDataConsumerRenderParams) => {
  const { setValue } = useFormContext();

  const business_type = scopedFormData?.business_type;

  const disabled = !scopedFormData || !business_type;

  const directionChoices = useMemo(() => {
    return ecpBidDirectionChoices.map((choice) => {
      const isFCR_D = business_type === ecpBidBusinessTypeEnum.FCR_D;
      const isFCR_N = business_type === ecpBidBusinessTypeEnum.FCR_N;

      const choiceDisabled =
        (isFCR_D && choice.id === EcpBidDirectionEnum.SYMMETRIC) ||
        (isFCR_N && choice.id !== EcpBidDirectionEnum.SYMMETRIC);

      return {
        ...choice,
        disabled: choiceDisabled,
      };
    });
  }, [business_type]);

  useEffect(() => {
    if (getSource) {
      setValue(getSource("direction"), "");
    }
  }, [business_type, setValue]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!getSource) return <></>;

  return (
    <>
      <NumberInput
        source={getSource("start_hour")}
        min={0}
        max={23}
        helperText="Finnish time"
        validate={required()}
        disabled={disabled}
      />
      <NumberInput
        source={getSource("end_hour")}
        min={1}
        max={24}
        helperText="Finnish time, must be greater than start hour"
        validate={required()}
        disabled={disabled}
      />
      <NumberInput
        source={getSource("offered_quantity_mw")}
        label="Offered Quantity (MW)"
        step={0.1}
        min={0}
        validate={required()}
        disabled={disabled}
      />
      <NumberInput
        source={getSource("offered_price_eur")}
        label="Offered Price (EUR/MW)"
        step={0.01}
        min={0}
        validate={required()}
        disabled={disabled}
      />
      <SelectInput
        source={getSource("direction")}
        choices={directionChoices}
        validate={required()}
        disabled={disabled}
      />
    </>
  );
};
