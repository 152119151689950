import { Box, Typography } from "@mui/material";
import CustomExportButton from "components/commons/buttons/CustomExportButton";
import { baseResources } from "config_infos";
import { useMemo } from "react";
import {
  CreateButton,
  Datagrid,
  DateField,
  DateTimeInput,
  FilterButton,
  List,
  ReferenceArrayField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  TextField,
  TextInput,
  TopToolbar,
  useRecordContext,
  useStore,
} from "react-admin";
import { useLocation, useNavigate } from "react-router-dom";

export const MaintenancesList = (props: any) => {
  const { pathname } = useLocation();
  const isSiteShow = pathname.includes("show");
  const navigate = useNavigate();
  const record = useRecordContext();
  // eslint-disable-next-line
  const [siteShowId, setSiteShowId] = useStore(`${baseResources.maintenance.MAINTENANCE}.list.siteShowId`);

  const handleClick = () => {
    setSiteShowId(record?.id || "");
    navigate(`/${baseResources.maintenance.MAINTENANCE}/create`);
  };

  const customFilters = useMemo(
    () => [
      <SearchInput key={"search"} source="search" alwaysOn />,
      <TextInput key="type" label="Type" source="type" />,
      <DateTimeInput key="start" label="Start date" source="start" />,
      <DateTimeInput key="start__gt" label="Start date greater than" source="start__gt" />,
      <DateTimeInput key="start__lt" label="Start date lower than" source="start__lt" />,
      <DateTimeInput key="end" label="End Date" source="end" />,
      <DateTimeInput key="end__gt" label="End Date greater than" source="end__gt" />,
      <DateTimeInput key="end__lt" label="End Date lower than" source="end__lt" />,
      ...(!isSiteShow
        ? [<ReferenceInput key={"site_id"} label="Site" source="site_id" reference={baseResources.sites.SITES} />]
        : []),
    ],
    [isSiteShow],
  );

  return (
    <List
      {...props}
      title={isSiteShow ? " " : "Maintenance"}
      filters={customFilters}
      filterDefaultValues={{ end__gt: new Date().toISOString() }}
      actions={
        <TopToolbar>
          <FilterButton />
          <CreateButton onClick={handleClick} />
          <CustomExportButton includeDeleted filename="maintenances" />
        </TopToolbar>
      }
      empty={
        <Box textAlign={"center"} margin={"auto"}>
          <Typography variant="h6" fontStyle={"italic"} gutterBottom color={"grey"}>
            Pas de maintenance en cours
          </Typography>
          <CreateButton onClick={handleClick} />
        </Box>
      }
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="id" sortable={false} />
        <DateField source="start" sortable={true} showTime />
        <DateField source="end" sortable={true} showTime />
        <ReferenceField source="site_id" sortable={false} reference={baseResources.sites.SITES} link="show" />
        <ReferenceField
          source="site_id"
          sortable={false}
          reference={baseResources.sites.SITES}
          link={false}
          label={"Groups"}
        >
          <ReferenceArrayField source="group_ids" reference={baseResources.sites.GROUPS} filter={{ type: "rte_edr" }} />
        </ReferenceField>
        <TextField source="type" sortable={false} />
        <TextField source="operator" sortable={false} />
        <TextField source="comment" sortable={false} />
        <DateField source="updated_at" sortable={false} showTime />
        <DateField source="created_at" sortable={false} showTime />
      </Datagrid>
    </List>
  );
};
