import { DataProvider, GetListParams } from "react-admin";

import { RaRecord, Identifier } from "react-admin";

export const fetchAllRecords = async <T extends RaRecord<Identifier>>(
  dataProvider: DataProvider,
  resource: string,
  resourceConfig?: GetListParams,
  includeDeleted = false,
): Promise<T[]> => {
  let page = 1;
  let dataAccumulator: T[] = [];
  let total = 0;

  do {
    const { data, total: newTotal } = await dataProvider.getList<T>(resource, {
      pagination: { page, perPage: 100 },
      sort: { field: "id", order: "ASC" },
      filter: {},
      ...resourceConfig,
    });

    dataAccumulator = [...dataAccumulator, ...data];
    total = newTotal ?? total;
    page += 1;
  } while ((page - 1) * 100 < total);

  if (includeDeleted) return dataAccumulator;

  return dataAccumulator.map((record: T & { deleted_at?: any }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { deleted_at, ...rest } = record;
    return rest;
  }) as T[];
};
