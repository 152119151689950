import {
  SimpleForm,
  TextField,
  DateField,
  Labeled,
  Show,
  NumberField,
  UrlField,
  Toolbar,
  SaveButton,
} from "react-admin";
import { Grid, Box } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
const { REACT_APP_TOPAZE_API_URL } = process.env;

export const RTE_GateShow = () => {
  const sendPA = (data: any) => {
    console.log("tot is goo" + JSON.stringify(data));
    if (data !== null && data["xml_file_link"] !== null) {
      const xml_file_link: string = data["xml_file_link"].split("?")[0];
      fetch(`${REACT_APP_TOPAZE_API_URL}/pa/send`, {
        method: "POST",
        body: JSON.stringify({
          xml_file_link: xml_file_link,
        }),
        headers: { "Content-Type": "application/json" },
      }).then((response) => {
        console.log("response send pa : " + response.json());
      });
    }
  };

  const SendPAToolbar = () => (
    <Toolbar>
      <SaveButton label="SEND PA" alwaysEnable icon={<SendIcon />} />
    </Toolbar>
  );

  return (
    <Show>
      <SimpleForm onSubmit={sendPA} toolbar={<SendPAToolbar />}>
        <div>
          <Grid container width={{ xs: "100%", xl: 800 }} spacing={2}>
            <Grid item xs={12} md={8}>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="id">
                    <NumberField source="id" />
                  </Labeled>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="revision_id">
                    <NumberField source="revision_id" />
                  </Labeled>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="deposit_status_code">
                    <NumberField source="deposit_status_code" />
                  </Labeled>
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="application_gate">
                    <DateField source="application_gate" showTime />
                  </Labeled>
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="deposit_date">
                    <DateField source="deposit_date" showTime />
                  </Labeled>
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="target_gate">
                    <DateField source="target_gate" showTime />
                  </Labeled>
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="rte_ticket_id">
                    <TextField source="rte_ticket_id" />
                  </Labeled>
                </Box>
              </Box>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="xml_file_link">
                    <UrlField source="xml_file_link" />
                  </Labeled>
                </Box>
                <Box flex={2} ml={{ xs: 0, sm: "0.5em" }} />
              </Box>
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Show>
  );
};
