import { DateField, Datagrid, List, TextField, NumberField, TextInput, NumberInput, DateTimeInput } from "react-admin";

const postFilters = [
  <NumberInput key="edp" label="edp" source="edp" />,
  <DateTimeInput key="start" label="start" source="start" />,
  <DateTimeInput key="end" label="end" source="end" />,
  <TextInput key="operator" label="operator" source="operator" />,
];

//change text fils
export const PAList = () => (
  <List filters={postFilters}>
    <Datagrid rowClick="show">
      <NumberField source="id" />
      <NumberField source="edp" sortable={false} />
      <TextField source="operator" />
      <NumberField source="r1_h" sortable={false} />
      <NumberField source="r1_b" sortable={false} />
      <NumberField source="r2_h" sortable={false} />
      <NumberField source="r2_b" sortable={false} />
      <NumberField source="p0" sortable={false} />
      <DateField source="start" showTime />
      <DateField source="end" showTime />
    </Datagrid>
  </List>
);
