import { Box, Grid, Stack, Typography } from "@mui/material";
import "chartjs-adapter-date-fns";
import "chartjs-adapter-moment";
import {
  AutocompleteInput,
  Button,
  DateInput,
  Loading,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  SimpleForm,
  required,
} from "react-admin";
import useFetchMasksChartData from "./hooks/useFetchMasksChartData";

import { DateRangeType, FetchInfosType, MaskType } from "types";

import { maskModeColors } from "components/charts/planning/helpers";
import { baseResources } from "config_infos";
import { addDays, format, startOfDay } from "date-fns";
import { PLANNING_MASK_MODE_ENUM } from "enums";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";

type TooltipFormatterPropType = {
  start: number;
  end: number;
};

export default function PlanningMaskChart() {
  const [formPickerState, setFormPickerState] = useState<FetchInfosType>({
    groupIds: [],
    dateRange: {
      startDate: startOfDay(new Date()),
      endDate: addDays(startOfDay(new Date()), 3),
    },
  });

  const { error, loading, masks, edrs } = useFetchMasksChartData(formPickerState);

  const series = Object.keys(PLANNING_MASK_MODE_ENUM).map(
    (
      operatingMode: any,
    ): {
      name: string;
      data: any[];
    } => {
      return {
        name: operatingMode,
        data: [],
      };
    },
  );

  edrs.forEach(({ sites, name: edrName, id: edrId }: any) => {
    const isEdrSelected = formPickerState.groupIds.length === 0 || formPickerState.groupIds.includes(edrId);
    if (sites.length === 0 || !isEdrSelected) {
      return;
    }
    // all sites in the EDR have the same masks, so we can take the masks of the first site
    const site = sites[0];
    const siteMasks = masks.filter((mask: MaskType) => mask.site_id === site.id);
    // masks are separated by operating_mode
    siteMasks.forEach((mask: MaskType) => {
      const maskType = mask.operating_mode;
      const maskData = {
        x: edrName,
        y: [new Date(mask.start_date).getTime(), new Date(mask.end_date).getTime()],
      };
      const targetedSerie = series.find((serie: any) => serie.name === maskType);
      if (targetedSerie) {
        targetedSerie.data.push(maskData);
      }
    });
  });

  const NonEmptySeries = series.filter((serie: any) => {
    return serie.data.length > 0;
  });

  const state = {
    series: NonEmptySeries,
    options: {
      chart: {
        height: "100%",
        type: "bar",
        toolbar: {
          show: false, // Disables the toolbar (zoom, pan, reset options) for the chart.
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "75%",
          rangeBarGroupRows: true,
        },
      },
      colors: NonEmptySeries.map((serie: any) => maskModeColors[serie.name]),
      xaxis: {
        type: "datetime",
        labels: {
          formatter: function (value: number) {
            return format(new Date(value), "dd/MM/yyyy HH:mm");
          },
        },
        min: formPickerState.dateRange.startDate.getTime(),
        max: formPickerState.dateRange.endDate.getTime(),
      },
      yaxis: {},
      stroke: {
        width: 2,
      },
      fill: {
        type: "solid",
        opacity: 0.6,
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
      },
      tooltip: {
        x: {
          formatter: function (edrName: string, props: TooltipFormatterPropType | undefined) {
            // eslint-disable-next-line react/prop-types
            if (!edrName || !props || !props.start || !props.end) return "";
            // eslint-disable-next-line react/prop-types
            const { start, end }: TooltipFormatterPropType = props;
            return `${edrName} : ${format(new Date(start), "dd/MM/yyyy HH:mm")} - ${format(
              new Date(end),
              "dd/MM/yyyy HH:mm",
            )}`;
          },
        },
      },
    },
  };

  const handleSubmit = (values: any) => {
    const dateRange: DateRangeType = {
      startDate: new Date(values.startDate),
      endDate: addDays(new Date(values.startDate), +values.dateRange),
    };
    setFormPickerState({
      groupIds: values.groupIds,
      dateRange,
    });
  };

  return (
    <Grid container width={{ xs: "100%", md: "80vw" }} spacing={2}>
      <Grid item container xs={12} md={12}>
        <Grid item xs={12}>
          <SimpleForm
            onSubmit={handleSubmit}
            toolbar={false}
            sx={{
              borderRadius: 2,
              border: "1px solid gainsboro",
              padding: 0,
              pl: 2,
              m: 2,
            }}
          >
            <Box display={"flex"} justifyContent={"space-evenly"} width={"100%"} gap={2}>
              <DateInput validate={required()} source="startDate" defaultValue={new Date()} fullWidth />
              <ReferenceArrayInput
                reference={baseResources.sites.GROUPS}
                source="groupIds"
                label="Groups"
                perPage={100}
                filter={{
                  type: "rte_edp",
                }}
                defaultValue={formPickerState.groupIds}
              >
                <AutocompleteInput optionText="name" fullWidth />
              </ReferenceArrayInput>
              <RadioButtonGroupInput
                validate={required()}
                source="dateRange"
                choices={[
                  { id: "1", name: "1 Day" },
                  { id: "3", name: "3 Days" },
                  { id: "7", name: "7 Days" },
                ]}
                defaultValue={3}
                row={true}
                fullWidth
              />
            </Box>
            <Box margin={"auto"}>
              <Button type="submit" label="search" variant="contained" size="large" sx={{ width: 300 }} />
            </Box>
          </SimpleForm>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} height={"100vh"} paddingBottom={20}>
        {error && (
          <Stack spacing={2}>
            <Typography variant="h6" color="error" fontWeight={"bold"}>
              Error
            </Typography>
            <Typography color="error">{error.message}</Typography>
          </Stack>
        )}
        {loading ? (
          <Loading />
        ) : (
          <ReactApexChart options={state.options as any} series={state.series} type="rangeBar" height={"100%"} />
        )}
      </Grid>
    </Grid>
  );
}
