import {
  Create,
  SimpleForm,
  TextInput,
  SimpleFormIterator,
  ArrayInput,
  NumberInput,
  DateTimeInput,
  BooleanInput,
} from "react-admin";

const { REACT_APP_TOPAZE_API_URL } = process.env;

const validatePACreate = () => {
  const errors = {};
  return errors;
};

export const PACreate = () => {
  const postPAMany = (data: any) => {
    let endpoint = "generate";
    if (data["generate_only"] === false) {
      endpoint = "many";
    }
    delete data["generate_only"];
    console.log("my about to be posted data be like : " + JSON.stringify(data));
    fetch(`${REACT_APP_TOPAZE_API_URL}/pa/${endpoint}`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    }).then((response) => {
      console.log("response create pa : " + response.json());
    });
  };

  const postPADefaultValue = () => ({
    generate_only: true,
  });

  return (
    <Create>
      <SimpleForm validate={validatePACreate} onSubmit={postPAMany} defaultValues={postPADefaultValue}>
        <BooleanInput label="Generate Only" source="generate_only" />
        <TextInput source="operator" />
        <ArrayInput source="edp_list">
          <SimpleFormIterator inline>
            <NumberInput source="edp"></NumberInput>
            <ArrayInput source="pa_list">
              <SimpleFormIterator inline>
                <DateTimeInput label="start" source="start" />
                <DateTimeInput label="end" source="end" />
                <NumberInput label="r1_h" source="r1_h" />
                <NumberInput label="r1_b" source="r1_b" />
                <NumberInput label="r2_h" source="r2_h" />
                <NumberInput label="r2_b" source="r2_b" />
                <NumberInput label="p0" source="p0" />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};
