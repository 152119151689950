import { Avatar, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Switch, Typography } from "@mui/material";
import { FieldArray, Form as FormikForm, useFormikContext } from "formik";
import { Button, Toolbar } from "react-admin";
import { FormikFieldsType, RteAfrrPLanningSubFormType, SiteType } from "./types";
import { PlanningTypeEnum } from "./helpers";
import { useCallback, useMemo } from "react";
import { FormikField } from "components/commons/inputs/FormikInput";

export default function TestsAfrrRtePlanningsForm() {
  return (
    <FormikForm>
      <FieldArray name="plannings" render={() => <RteAfrrPLanningsForm />} />
      <Toolbar
        sx={{
          mt: 2,
        }}
      >
        <Button type="submit" color="primary" variant="contained">
          <Typography>Valider</Typography>
        </Button>
      </Toolbar>
    </FormikForm>
  );
}

export type SitesListProps = {
  sites: SiteType[];
  sitesName: string;
};

const SitesList = ({ sites, sitesName }: SitesListProps) => {
  const { handleChange } = useFormikContext<FormikFieldsType>();

  const rackNumber = useMemo(() => (sitesName === "site8racks" ? 8 : 10), [sitesName]);

  if (!sites?.length) return <></>;

  return (
    <List
      subheader={<Typography variant="h6">{`${rackNumber} Racks`}</Typography>}
      sx={{
        ml: 2,
      }}
    >
      {sites.map((site: SiteType, index) => (
        <ListItem key={site.id}>
          <ListItemIcon>
            <Avatar>{site.id}</Avatar>
          </ListItemIcon>
          <ListItemText primary={site.name} />
          <Switch
            edge="end"
            name={`${sitesName}[${index}].available`}
            onChange={handleChange}
            checked={site.available}
          />
        </ListItem>
      ))}
    </List>
  );
};

const RteAfrrPLanningsForm = () => {
  const { values }: any = useFormikContext();
  return values?.plannings ? (
    <Grid container spacing={2}>
      <Grid item container wrap="nowrap" xs={12}>
        <Grid item xs={6}>
          <SitesList sites={values.site8racks} sitesName="site8racks" />
        </Grid>
        <Grid item xs={6}>
          <SitesList sites={values.site10racks} sitesName="site10racks" />
        </Grid>
      </Grid>
      <Grid item container wrap="nowrap" xs={12} spacing={2}>
        <RteAfrrPLanningSubForm planningsName={"plannings8Racks"} />
        <RteAfrrPLanningSubForm planningsName={"plannings10Racks"} />
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

const RteAfrrPLanningSubForm = ({ planningsName }: RteAfrrPLanningSubFormType) => {
  const { values, errors, touched }: any = useFormikContext();

  const planningsByRacks = values.plannings[planningsName];

  const triggerError = useCallback((errors: any, touched: any, field: string) => {
    return errors && touched && errors[field] && touched[field];
  }, []);

  return (
    <Grid item container rowGap={2} mt={2}>
      {planningsByRacks &&
        planningsByRacks.length > 0 &&
        planningsByRacks.map((planning: any, index: number) => {
          const baseName = `plannings.${planningsName}[${index}]`;
          const currentPlanning = planningsByRacks[index];
          const planningErrors =
            errors && errors?.plannings && errors?.plannings[planningsName] && errors?.plannings[planningsName][index];
          const planningTouched =
            touched &&
            touched?.plannings &&
            touched?.plannings[planningsName] &&
            touched?.plannings[planningsName][index];
          return (
            <Grid
              item
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              key={index}
              spacing={2}
              borderRadius={2}
              component={Paper}
              elevation={4}
              mt={1}
              pb={2}
              pr={2}
              ml={0}
            >
              <Grid item>
                <Typography variant="h6">{planning.name}</Typography>
              </Grid>

              <Grid item>
                <Typography fontWeight={"bold"}>{"Start date (utc) : "}</Typography>
                <Typography>{currentPlanning.start_date}</Typography>
              </Grid>
              <Grid item>
                <Typography fontWeight={"bold"}>{"End date (utc): "}</Typography>
                <Typography>{currentPlanning.end_date}</Typography>
              </Grid>
              <Grid item>
                <Typography fontWeight={"bold"}>{"Duration"}</Typography>
                <Typography>
                  {currentPlanning.duration.hours && `${currentPlanning.duration.hours}h`}
                  {currentPlanning.duration.minutes && `${currentPlanning.duration.minutes}min`}
                </Typography>
              </Grid>

              {planning.type === PlanningTypeEnum.AFRR ? (
                <>
                  <Grid item>
                    <Typography fontWeight={"bold"}>{"Chronicle filename"}</Typography>
                    <Typography>{currentPlanning.chronicle_filename}</Typography>
                  </Grid>
                  <Grid item>
                    <FormikField
                      error={triggerError(planningErrors, planningTouched, "afrr_engagement")}
                      errorMessage={planningErrors?.afrr_engagement}
                      type="number"
                      name={`${baseName}.afrr_engagement`}
                      label={"AFRR engagement"}
                    />
                  </Grid>
                  <Grid item>
                    <FormikField
                      error={triggerError(planningErrors, planningTouched, "initial_soc_restoration_power")}
                      errorMessage={planningErrors?.initial_soc_restoration_power}
                      type="number"
                      name={`${baseName}.initial_soc_restoration_power`}
                      default={0}
                      label={"Initial soc restoration power"}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item>
                  <FormikField
                    error={triggerError(planningErrors, planningTouched, "target_soc")}
                    errorMessage={planningErrors?.target_soc}
                    type="number"
                    name={`${baseName}.target_soc`}
                    label={"Target soc"}
                  />
                </Grid>
              )}
            </Grid>
          );
        })}
    </Grid>
  );
};
