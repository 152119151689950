import { DateField, Edit, Labeled, SimpleForm, useTranslate } from "react-admin";
import { Grid, Box, Typography } from "@mui/material";

export const RTE_Gate_StatusEdit = () => {
  const translate = useTranslate();
  return (
    <Edit>
      <SimpleForm>
        <div>
          <Grid container width={{ xs: "100%", xl: 800 }} spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography variant="h6" gutterBottom>
                {translate("resources.groups.info")}
              </Typography>

              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="created at">
                    <DateField source="created_at" fullWidth />
                  </Labeled>
                  <Labeled source="updated at">
                    <DateField source="updated_at" fullWidth />
                  </Labeled>
                </Box>
                <Box flex={2} ml={{ xs: 0, sm: "0.5em" }} />
              </Box>
              <Box display={{ xs: "block", sm: "flex" }}>
                {/* <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                    <TextInput source="name" fullWidth />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <TextInput source="code" fullWidth />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                    <TextInput source="code" fullWidth />
                  </Box> */}
              </Box>
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  );
};
