import { Box } from "@mui/material";
import { baseResources } from "config_infos";
import { planningMaskModeChoices } from "enums/reactAdminChoices";
import { useMemo, useState } from "react";
import {
  AutocompleteArrayInput,
  Button,
  Datagrid,
  DateField,
  DateTimeInput,
  List,
  ReferenceArrayInput,
  ReferenceField,
  TextField,
  TextInput,
} from "react-admin";
import PlanningMaskChart from "../../../charts/planning/PlanningMaskChart";

export const PlanningMaskList = () => {
  const [isListMode, setIsListMode] = useState(true);

  const toggleViewMode = () => {
    setIsListMode((prevState) => !prevState);
  };

  const planningMasksFilters = useMemo(
    () => [
      <DateTimeInput key="start_date__lt" label="Start date before" source="start_date__lt" />,
      <DateTimeInput key="start_date__gt" label="Start date after" source="start_date__gt" />,
      <DateTimeInput key="end_date__lt" label="End date before" source="end_date__lt" />,
      <DateTimeInput key="end_date__gt" label="End date after" source="end_date__gt" />,
      <TextInput key="operating_mode" source="operating_mode" />,
      <AutocompleteArrayInput key="operating_mode__in" source="operating_mode__in" choices={planningMaskModeChoices} />,
      <ReferenceArrayInput key="site_id__in" source="site_id__in" reference={baseResources.sites.SITES} label="Sites">
        <AutocompleteArrayInput label="Sites" blurOnSelect={false} disableCloseOnSelect sx={{ minWidth: 300 }} />
      </ReferenceArrayInput>,
      <TextInput key="operator_id" source="operator_id" />,
    ],
    [],
  );

  const listProps = isListMode
    ? {
        filters: planningMasksFilters,
        perPage: 10,
      }
    : {
        pagination: <></>,
      };

  return (
    <Box>
      <Button
        label={isListMode ? "View Graph" : "View List"}
        variant="contained"
        size="large"
        onClick={toggleViewMode}
        sx={{ mt: 2 }}
      />
      <List
        {...listProps}
        exporter={false}
        filterDefaultValues={{
          end_date__gt: new Date().toISOString(),
        }}
      >
        {isListMode ? (
          <Datagrid bulkActionButtons={false}>
            <TextField source="site_id" />
            <ReferenceField source="site_id" reference={baseResources.sites.SITES} link={"show"}>
              <TextField source="name" />
            </ReferenceField>
            <TextField source="operating_mode" />
            <DateField showTime source="start_date" />
            <DateField showTime source="end_date" />
            <TextField source="operator_id" />
          </Datagrid>
        ) : (
          <PlanningMaskChart />
        )}
      </List>
    </Box>
  );
};
