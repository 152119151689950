import { useMemo } from "react";
import { Datagrid, List, SelectInput, ShowButton, TextField } from "react-admin";
import { optimizerCountryChoices, optimizerMarketChoices } from "../opti_enums";

export default function CertificationsList() {
  const CertificationsFilters = useMemo(
    () => [
      <SelectInput key="market" label="Market" source="market" choices={optimizerMarketChoices} />,
      <SelectInput key="country" label="Country" source="country" choices={optimizerCountryChoices} />,
    ],
    [],
  );

  return (
    <List filters={CertificationsFilters}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="certif_name" />
        <TextField source="market" />
        <TextField source="country" />
        <ShowButton />
      </Datagrid>
    </List>
  );
}
