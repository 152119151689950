import { Create } from "react-admin";
import SitesBaseForm from "./SitesBaseForm";

export const SiteCreate = () => {
  return (
    <Create>
      <SitesBaseForm />
    </Create>
  );
};
