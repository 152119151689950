import { Grid } from "@mui/material";
import { Labeled, Show, SimpleShowLayout, TextField } from "react-admin";

export default function GroupsCertificationsShow() {
  return (
    <Show>
      <SimpleShowLayout>
        <Grid item xs={4}>
          <Labeled source="name">
            <TextField source="name" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled source="group_type">
            <TextField source="group_type" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled source="group_code">
            <TextField source="group_code" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled source="certification_id">
            <TextField source="certification_id" />
          </Labeled>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
}
