import {
  SimpleForm,
  DateTimeInput,
  NumberInput,
  ReferenceArrayInput,
  required,
  AutocompleteArrayInput,
} from "react-admin";
import { useState } from "react";
import { Alert } from "@mui/material";
import FormContainer from "components/commons/forms/FormContainer";
import { baseResources } from "config_infos";
import { validatePlanningTime } from "components/validations";

type Props = {
  handleClick: (values: object) => void;
};

export default function TestsRteInitialForm({ handleClick }: Props) {
  const [touchedFields, setTouchedFields] = useState<string[]>([]);

  const handleFieldBlur = (fieldName: string) => {
    if (!touchedFields.includes(fieldName)) {
      setTouchedFields([...touchedFields, fieldName]);
    }
  };

  const handleSubmit = (values: object) => {
    setTouchedFields([]);
    handleClick(values);
  };

  return (
    <SimpleForm onSubmit={handleSubmit} mode="onBlur">
      <FormContainer>
        <ReferenceArrayInput label="Sites" source="site_ids" reference={baseResources.sites.SITES} perPage={100}>
          <AutocompleteArrayInput onBlur={() => handleFieldBlur("site_ids")} />
        </ReferenceArrayInput>
        <DateTimeInput
          source="startingDate"
          validate={[required(), validatePlanningTime]}
          onBlur={() => handleFieldBlur("startingDate")}
        />
        <NumberInput
          source="soc_trial_1_3"
          defaultValue={0.445}
          validate={[required()]}
          onBlur={() => handleFieldBlur("soc_trial_1_3")}
        />
        <NumberInput
          source="soc_trial_2_4"
          defaultValue={0.53}
          validate={[required()]}
          onBlur={() => handleFieldBlur("soc_trial_2_4")}
        />
        <NumberInput
          source="fcr_engagement_default"
          defaultValue={1}
          validate={[required()]}
          onBlur={() => handleFieldBlur("fcr_engagement_default")}
        />
        <NumberInput
          source="initial_soc_restoration_1_3"
          defaultValue={0.25}
          validate={[required()]}
          onBlur={() => handleFieldBlur("initial_soc_restoration_1_3")}
        />
        <NumberInput
          source="initial_soc_restoration_2_4"
          defaultValue={-0.25}
          validate={[required()]}
          onBlur={() => handleFieldBlur("initial_soc_restoration_2_4")}
        />
      </FormContainer>
      {touchedFields.length > 0 && (
        <Alert
          severity="warning"
          sx={{
            width: "100%",
          }}
        >
          <strong>The changes need to be validated to be taken into account</strong>
        </Alert>
      )}
    </SimpleForm>
  );
}
