import { useRecordContext } from "react-admin";
import formatDuration from "date-fns/formatDuration";
import { intervalToDuration } from "date-fns";

interface DurationFieldProps {
  source: string;
}

const DurationField: React.FC<DurationFieldProps> = ({ source }: DurationFieldProps) => {
  const record = useRecordContext();
  if (!record) return null;
  const parsedDuration = intervalToDuration({
    start: 0,
    end: record[source] * 1000,
  });
  return <span>{formatDuration(parsedDuration)}</span>;
};

export default DurationField;
