import { add } from "date-fns";
import { PLANNING_MODE_ENUM } from "enums";
import { baseAfrrValues, PlanningRoleEnum } from "./helpers";

const addFieldsToPlanningValues = (initialValues) => {
  let currentStart = initialValues.start_date;
  return baseAfrrValues.reduce((acc, currentPlanning) => {
    if (
      !initialValues.plannedTests.includes(currentPlanning.testsParticipation) &&
      currentPlanning.testsParticipation !== "all"
    ) {
      return acc;
    }
    const start_date = currentStart;
    const end_date = add(currentStart, currentPlanning.duration);
    currentStart = end_date;
    const target_soc =
      currentPlanning.mode === PLANNING_MODE_ENUM.soc_management ? initialValues[currentPlanning.role] : 0;
    const initial_soc_restoration_power =
      currentPlanning.role === PlanningRoleEnum.pProgTrial1 || currentPlanning.role === PlanningRoleEnum.pProgTrial3
        ? initialValues[currentPlanning.role]
        : 0;
    return [
      ...acc,
      {
        ...currentPlanning,
        chronicle_filename: currentPlanning.chronicle_filename || "",
        overidable: false,
        afrr_engagement: initialValues.afrr_engagement || 0,
        target_soc,
        initial_soc_restoration_power,
        start_date: start_date.toISOString(),
        end_date: end_date.toISOString(),
      },
    ];
  }, []);
};

export const generateInitialPlanningsValues = (initFormValues) => {
  if (initFormValues) {
    const {
      Pprog_trial_1_8_racks,
      Pprog_trial_2_8_racks,
      Pprog_trial_1_10_racks,
      Pprog_trial_2_10_racks,
      soc_trial_1,
      soc_trial_2,
      startingDate,
      endingDate,
      afrr_engagement_8_racks,
      afrr_engagement_10_racks,
      sites,
      plannedTests,
    } = initFormValues;

    const commonValues = {
      socTrial1: soc_trial_1,
      socTrial2: soc_trial_2,
      socTrial3: 0.5,
      pProgTrial2: 0,
      pProgTrial4: 0,
      start_date: startingDate,
      end_date: endingDate,
      plannedTests,
    };

    const site8racks = sites?.filter((site) => site.rack_count === 8);
    const site10racks = sites?.filter((site) => site.rack_count === 10);

    return {
      plannings: {
        plannings8Racks: addFieldsToPlanningValues({
          ...commonValues,
          pProgTrial1: Pprog_trial_1_8_racks,
          pProgTrial3: Pprog_trial_2_8_racks,
          afrr_engagement: afrr_engagement_8_racks,
        }),
        plannings10Racks: addFieldsToPlanningValues({
          ...commonValues,
          pProgTrial1: Pprog_trial_1_10_racks,
          pProgTrial3: Pprog_trial_2_10_racks,
          afrr_engagement: afrr_engagement_10_racks,
        }),
      },
      site8racks,
      site10racks,
    };
  }
  return {};
};
