import { Grid } from "@mui/material";
import { Labeled, Show, SimpleShowLayout, TextField } from "react-admin";

export default function CertificationsShow() {
  return (
    <Show>
      <SimpleShowLayout>
        <Grid item xs={4}>
          <Labeled source="certif_name">
            <TextField source="certif_name" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled source="market">
            <TextField source="market" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled source="country">
            <TextField source="country" />
          </Labeled>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
}
