import { Show, TabbedShowLayout } from "react-admin";
import GroupBasicInfos from "./showTabs/GroupBasicInfos";
import PlanningsAndMasks from "./showTabs/PlanningsAndMasks";
import RteCommunicationGraphVisualizations from "./showTabs/RteCommunicationGraphVisualizations";
import GroupMaintenances from "./showTabs/GroupMaintenances";
import NLevelGraphVisualization from "./showTabs/NLevelGraphVisualization";
import GroupMapContainer from "./showTabs/GroupMap";

export const GroupShow = () => {
  return (
    <Show>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Basic Infos">
          <GroupBasicInfos />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="RTE communication">
          <RteCommunicationGraphVisualizations />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Plannings and Masks">
          <PlanningsAndMasks />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Maintenances">
          <GroupMaintenances />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="N level">
          <NLevelGraphVisualization />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Map">
          <GroupMapContainer />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};
