import { baseResources } from "config_infos";
import { useCallback, useEffect, useState } from "react";
import { useDataProvider, useNotify, useRedirect } from "react-admin";
import { useLocation } from "react-router-dom";
import { EcpBidType, EcpGateWithBidsType } from "../types";
import EcpGateBaseForm from "./EcpGateBaseForm";

export default function EcpGateEdit() {
  const dataprovider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  const { pathname } = useLocation();

  const [defaultValues, setDefaultValues] = useState<EcpGateWithBidsType | null>(null);

  const fetchGate = useCallback(async () => {
    const { data } = await dataprovider.getOne(baseResources.ecp.GATES, {
      id: pathname.split("/").pop(),
    });
    return data;
  }, [dataprovider, pathname]);

  useEffect(() => {
    fetchGate()
      .then((response: any) => {
        if (!response) {
          throw new Error("No gate found");
        }
        const formattedResponse = {
          ...response,
          bids: response.bids.map(
            ({ start_date, offered_quantity_mw, offered_price_eur, direction, business_type }: EcpBidType) => ({
              offered_quantity_mw,
              offered_price_eur,
              direction,
              business_type,
              start_hour: new Date(start_date).getHours(),
            }),
          ),
        };
        setDefaultValues(formattedResponse);
      })
      .catch((error) => {
        notify(`Error: ${error.message}`, { type: "error" });
        redirect("list", baseResources.ecp.GATES);
      });
  }, [fetchGate, notify, redirect]);

  return defaultValues ? <EcpGateBaseForm isEdit defaultValues={defaultValues} /> : null;
}
