import { SimpleForm, useTranslate, TextField, DateField, Labeled, Show, NumberField } from "react-admin";
import { Grid, Box, Typography } from "@mui/material";

export const RTE_Gate_StatusShow = () => {
  const translate = useTranslate();
  return (
    <Show
    // title={<VisitorTitle />}
    // aside={<Aside />}
    >
      <SimpleForm
      // validate={validateForm}
      >
        <div>
          <Grid container width={{ xs: "100%", xl: 800 }} spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography variant="h6" gutterBottom>
                {translate("resources.groups.info")}
              </Typography>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="id">
                    <NumberField source="id" fullWidth />
                  </Labeled>
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="updated_at">
                    <DateField source="updated_at" showTime />
                  </Labeled>
                </Box>
              </Box>
              <Box flex={1} ml={{ xs: "block", sm: "flex" }}>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="rte_gate_id">
                    <NumberField source="rte_gate_id" fullWidth />
                  </Labeled>
                </Box>
                <Box display={{ xs: "block", sm: "flex" }}>
                  <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                    <Labeled source="rte_gate_revision_id">
                      <NumberField source="rte_gate_revision_id" />
                    </Labeled>
                  </Box>
                  <Box flex={2} ml={{ xs: 0, sm: "0.5em" }} />
                </Box>
              </Box>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="gate_status">
                    <TextField source="gate_status" />
                  </Labeled>
                </Box>
                <Box flex={2} ml={{ xs: 0, sm: "0.5em" }} />
              </Box>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="compute_mode">
                    <TextField source="compute_mode" />
                  </Labeled>
                </Box>
                <Box flex={2} ml={{ xs: 0, sm: "0.5em" }} />
              </Box>
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Show>
  );
};
