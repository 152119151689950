import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

export const SiteMap = (props: any) => {
  const { latitude, longitude, name, height } = props;
  if (!latitude || !longitude) return null;

  return (
    <div className="leaflet-container" style={{ height: height }}>
      <MapContainer center={[latitude, longitude]} zoom={6} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={[latitude, longitude]}>
          <Popup>{name}</Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};
