import { DateField, NumberField, Show, SimpleShowLayout, TextField } from "react-admin";

export const AfrrEngagementBidTimeserieShow = () => (
  <Show>
    <SimpleShowLayout>
      <DateField showTime locales={"fr-FR"} source="created_at" />
      <DateField showTime locales={"fr-FR"} source="updated_at" />
      <DateField showTime locales={"fr-FR"} source="start_date" />
      <DateField showTime locales={"fr-FR"} source="end_date" />
      <TextField source="direction" />
      <NumberField source="offered_quantity" />
      <NumberField source="offered_price" />
      <TextField source="divisible" />
      <TextField source="exclusivity_group" />
      <TextField source="symetrical_feature" />
      <TextField source="mrid" />
      <TextField source="id" />
      <TextField source="gate_document_id" label="Gate Document ID" />
    </SimpleShowLayout>
  </Show>
);
