import { Grid } from "@mui/material";
import { Create, DateInput, SimpleForm, TextInput } from "react-admin";

export default function ManualBidsCreate() {
  return (
    <Create>
      <SimpleForm>
        <Grid container>
          <Grid item xs={4}>
            <TextInput source="product_name" />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="status" />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="deposit_operator" />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="currency" />
          </Grid>
          <Grid item xs={4}>
            <DateInput source="delivery_date" />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="accepted_quantity" />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="offered_quantity" />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="accepted_price" />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="offered_price" />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="country_code" />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="tso" />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="market" />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="zone" />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
}
