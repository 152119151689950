import { format } from "date-fns";

interface CrontabInfos {
  minute: number;
  hour: number;
  day_of_week: number;
  day_of_month: number;
  month_of_year: number;
  timezone: string;
  id?: string;
}

export const getFormattedCrontabInfos = (crontabInfos: CrontabInfos) => {
  return crontabInfos
    ? {
        minute: crontabInfos?.minute,
        hour: crontabInfos?.hour,
        dayOfWeek: "day_of_week" in crontabInfos ? format(new Date(2022, 0, crontabInfos?.day_of_week), "EEEE") : null,
        dayOfMonth: crontabInfos?.day_of_month,
        monthOfYear:
          "month_of_year" in crontabInfos ? format(new Date(2022, crontabInfos?.month_of_year - 1, 1), "MMMM") : null,
        timezone: crontabInfos?.timezone,
      }
    : null;
};

export const getHumanReadableCrontabInfos = (crontab: CrontabInfos) => {
  const formatJsonString = JSON.stringify(getFormattedCrontabInfos(crontab)).replaceAll(",", ", ");
  return (
    <span>
      <strong>{"Crontab : "}</strong>
      {formatJsonString}
    </span>
  );
};

interface IntervalInfos {
  every: number;
  period: string;
  id?: string;
}

export const getHumanReadableIntervalInfos = (interval: IntervalInfos) => {
  const formatJsonString = JSON.stringify(interval).replaceAll(",", ", ");
  return (
    <span>
      <strong>{"Interval: "}</strong>
      {formatJsonString}
    </span>
  );
};
