import { useMemo } from "react";
import {
  Datagrid,
  DateField,
  EditButton,
  List,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
  useResourceContext,
} from "react-admin";
import { getSelectOptions } from "helpers";
import { BIDS_CURRENCY, BIDS_PRODUCT_NAME, BIDS_ZONES, MARKET } from "enums";
import { baseResources } from "config_infos";
export const BidsList = () => {
  const resource = useResourceContext();
  const isManualBids = baseResources.regelleistung.MANUAL_BIDS === resource;

  const productNameOptions = useMemo(() => getSelectOptions(BIDS_PRODUCT_NAME), []);
  const marketOptions = useMemo(() => getSelectOptions(MARKET), []);
  const currencyOptions = useMemo(() => getSelectOptions(BIDS_CURRENCY), []);
  const zoneOptions = useMemo(() => getSelectOptions(BIDS_ZONES), []);

  const bidsFilters = useMemo(
    () => [
      <SelectInput key="market" label="market" source="market" choices={marketOptions} />,
      <SelectInput key="product_name" label="product_name" source="product_name" choices={productNameOptions} />,
      <TextInput key="status" label="status" source="status" />,
      <TextInput key="deposit_operator" label="deposit_operator" source="deposit_operator" />,
      <SelectInput key="currency" label="currency" source="currency" choices={currencyOptions} />,
      <TextInput key="delivery_date" label="delivery_date" source="delivery_date" />,
      <TextInput key="accepted_quantity" label="accepted_quantity" source="accepted_quantity" />,
      <TextInput key="offered_quantity" label="offered_quantity" source="offered_quantity" />,
      <TextInput key="accepted_price" label="accepted_price" source="accepted_price" />,
      <TextInput key="offered_price" label="offered_price" source="offered_price" />,
      <TextInput key="country_code" label="country_code" source="country_code" />,
      <TextInput key="tso" label="tso" source="tso" />,
      <SelectInput key="zone" label="zone" source="zone" choices={zoneOptions} />,
    ],
    [currencyOptions, marketOptions, productNameOptions, zoneOptions],
  );

  return (
    <List filters={bidsFilters}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        {!isManualBids && <TextField source="regel_bid_id" />}
        <TextField source="product_name" />
        <TextField source="status" />
        <TextField source="deposit_operator" />
        <TextField source="currency" />
        <DateField source="delivery_date" />
        <TextField source="accepted_quantity" />
        <TextField source="offered_quantity" />
        <TextField source="accepted_price" />
        <TextField source="offered_price" />
        <TextField source="country_code" />
        <TextField source="tso" />
        <TextField source="market" />
        <TextField source="zone" />
        {isManualBids && <EditButton />}
        <ShowButton />
      </Datagrid>
    </List>
  );
};
