import { Datagrid, List, TextField, NumberField, DateField } from "react-admin";

//change text fils
export const RTE_Gate_StatusList = () => (
  <List>
    <Datagrid rowClick="show">
      <NumberField source="id" />
      <TextField source="gate_status" />
      <NumberField source="rte_gate_id" />
      <NumberField source="rte_gate_revision_id" />
      <DateField source="updated_at" />
      <TextField source="compute_mode" />
    </Datagrid>
  </List>
);
