import { useState, useCallback, useEffect } from "react";
import { HEALTHCHECKS_INFOS } from "config_infos";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { AppBar, Box, Tab, Tabs } from "@mui/material";

export const HealthChecksContainer = () => {
  const initialParam = HEALTHCHECKS_INFOS[0].param || "";
  const { service } = useParams();
  const [value, setValue] = useState(initialParam);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const updateTab = useCallback((selectedParam: string) => {
    navigate(`${pathname}/${selectedParam}`);
    setValue(selectedParam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!service) {
      updateTab(initialParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialParam, service]);

  return (
    <Box sx={{ maxWidth: "100%", height: "100%", bgcolor: "background.paper" }}>
      <AppBar position="static" color="inherit">
        <Tabs value={value} variant="scrollable" scrollButtons="auto" aria-label="scrollable configuration auto tabs">
          {HEALTHCHECKS_INFOS.map(({ id, name, param }) => (
            <Tab key={id} label={name} value={param} onClick={() => updateTab(param)} />
          ))}
        </Tabs>
      </AppBar>
      <Outlet />
    </Box>
  );
};
