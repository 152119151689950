import { baseResources } from "config_infos";
import {
  Datagrid,
  DateField,
  ListContextProvider,
  NumberField,
  SortPayload,
  TextField,
  useGetList,
  useList,
} from "react-admin";

type PointsInRangeListProps = {
  filter: any;
};

export default function PointsInRangeList({ filter }: PointsInRangeListProps) {
  const { data } = useGetList(baseResources.afrrEngagement.POINT, {
    filter,
    pagination: { page: 1, perPage: 100 },
    sort: { field: "id", order: "ASC" } as SortPayload,
  });
  const listContext = useList({ data: data ? data.filter((item) => item.status !== "OBSOLETE") : [] });
  return (
    <ListContextProvider value={listContext}>
      <Datagrid bulkActionButtons={false}>
        <DateField showTime source="start_date" locales="fr-FR" />
        <DateField showTime source="end_date" locales="fr-FR" />
        <TextField source="status" />
        <TextField source="direction" />
        <NumberField source="offered_quantity" />
        <NumberField source="offered_price" />
        <NumberField source="accepted_quantity" />
        <NumberField source="accepted_price" />
      </Datagrid>
    </ListContextProvider>
  );
}
