export const removeLeadingTrailingSlash = (strItem: string) => {
  return strItem.startsWith("/") ? strItem.slice(1) : strItem;
};

export const getErrorMessages = (error: any) => {
  return error.body.detail.map(({ loc, msg }: any) => `${loc[1]} : ${msg}`);
};

export const getErrorMessagesAsString = (error: any) => {
  return error.body.detail.map(({ loc, msg }: any) => `${loc[1]} : ${msg}`).join(", ");
};

export const parseErrorMessage = (error: any) => {
  if (error.body && error.body.detail && Array.isArray(error.body.detail)) {
    return getErrorMessagesAsString(error);
  }
  if (error.body && error.body.detail && error.body.detail.error) {
    return error.body.detail.error;
  }
  if (error.body && error.body.detail) {
    return error.body.detail;
  }
  return error.message;
};

export function generateRandomString(length: number) {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const roundToNearestHour = (date: Date) => {
  if (!date) return date;
  const d = new Date(date);
  d.setMinutes(d.getMinutes() >= 30 ? 60 : 0, 0, 0);
  return d;
};

export const getEnumValues = (enumObject: any): any[] => {
  return Object.values(enumObject);
};

export const getEnumKeys = (enumObject: any): any[] => {
  return Object.keys(enumObject);
};
