import { Avatar } from "@mui/material";

type ColorTagProps = {
  color: string;
  width?: number;
  height?: number;
  border?: string;
};

const ColorTag = ({ color, width = 20, height = 20, border = "1px solid lightgrey" }: ColorTagProps) => {
  return color ? (
    <Avatar sx={{ bgcolor: color, width, height, border }}>
      <></>
    </Avatar>
  ) : null;
};

export default ColorTag;
