import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import BalconyIcon from "@mui/icons-material/Balcony";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoorBackIcon from "@mui/icons-material/DoorBack";
import EngineeringIcon from "@mui/icons-material/Engineering";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import GroupsIcon from "@mui/icons-material/Groups";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import NetworkCheckIcon from "@mui/icons-material/NetworkCheck";
import PsychologyIcon from "@mui/icons-material/Psychology";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import SignalCellularNoSimIcon from "@mui/icons-material/SignalCellularNoSim";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import UpdateIcon from "@mui/icons-material/Update";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { Divider, List, ListItem, ListSubheader } from "@mui/material";
import { baseResources } from "config_infos";
import { useCallback } from "react";
import { Menu, useSidebarState } from "react-admin";
import { useLocation } from "react-router-dom";
import { removeLeadingTrailingSlash } from "../helpers/helpers";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import BallotIcon from "@mui/icons-material/Ballot";

type SideBarListMenuProps = {
  items: { name: string; urlParam: string; icon: JSX.Element }[];
  listLabel: string;
};

const SideBarListMenu = ({ items, listLabel }: SideBarListMenuProps) => {
  const { pathname } = useLocation();
  const [open] = useSidebarState();
  const selectedItemSx = { bgcolor: "primary.main" };

  const evaluateCurrentPath = useCallback((itemUrlParam: string, currentPathname: string) => {
    return removeLeadingTrailingSlash(currentPathname) === removeLeadingTrailingSlash(itemUrlParam);
  }, []);

  return (
    <>
      {open ? (
        <List
          subheader={<ListSubheader sx={{ fontWeight: "bold", lineHeight: 2 }}>{listLabel}</ListSubheader>}
          dense
          sx={{ pb: 0 }}
        >
          {items.map((item) => {
            return (
              <ListItem key={item.name}>
                <Menu.Item
                  sx={{
                    width: "inherit",
                    bgcolor: evaluateCurrentPath(item.urlParam, pathname) ? "primary.main" : "transparent",
                  }}
                  to={item.urlParam}
                  primaryText={item.name}
                  leftIcon={item.icon}
                />
              </ListItem>
            );
          })}
        </List>
      ) : (
        items.map((item) => (
          <Menu.Item
            key={item.name}
            to={item.urlParam}
            primaryText={item.name}
            leftIcon={item.icon}
            sx={evaluateCurrentPath(item.urlParam, pathname) ? selectedItemSx : {}}
          />
        ))
      )}
      <Divider />
    </>
  );
};

const itemsInfos = [
  {
    listLabel: "Sites",
    listItems: [
      { name: "Sites", urlParam: baseResources.sites.SITES, icon: <FmdGoodIcon /> },
      { name: "Statuses", urlParam: baseResources.sites.STATUSES, icon: <NetworkCheckIcon /> },
      { name: "Groups", urlParam: baseResources.sites.GROUPS, icon: <GroupsIcon /> },
    ],
  },
  {
    listLabel: "Chronicles",
    listItems: [
      { name: "Validation", urlParam: baseResources.sites.PERFORMANCE_CHRONICLES, icon: <AlignVerticalBottomIcon /> },
      { name: "Generation", urlParam: "performance-chronicles-generation", icon: <EngineeringIcon /> },
    ],
  },
  {
    listLabel: "Topase",
    listItems: [
      { name: "PA", urlParam: baseResources.topase.PA, icon: <MailOutlineIcon /> },
      { name: "RTE Gates", urlParam: baseResources.topase.RTE_GATE, icon: <BalconyIcon /> },
    ],
  },
  {
    listLabel: "Regelleistung",
    listItems: [
      { name: "Bids", urlParam: baseResources.regelleistung.BIDS, icon: <ReceiptIcon /> },
      {
        name: "Manual Bids",
        urlParam: baseResources.regelleistung.MANUAL_BIDS,
        icon: <ReceiptOutlinedIcon />,
      },
      {
        name: "Results",
        urlParam: baseResources.regelleistung.RESULTS,
        icon: <RequestQuoteOutlinedIcon />,
      },
    ],
  },
  {
    listLabel: "Plannings",
    listItems: [
      {
        name: "Plannings",
        urlParam: baseResources.plannings.PLANNINGS,
        icon: <CalendarMonthIcon />,
      },
      {
        name: "Planning Masks",
        urlParam: baseResources.plannings.PLANNING_MASKS,
        icon: <UpdateIcon />,
      },
      {
        name: "Maintenances",
        urlParam: baseResources.maintenance.MAINTENANCE,
        icon: <EngineeringIcon />,
      },
    ],
  },
  {
    listLabel: "Tests TSO",
    listItems: [
      { name: "IEC Fake PAs", urlParam: baseResources.iec.FAKE_PAS, icon: <ReceiptLongIcon /> },
      { name: "IEC Loss N level", urlParam: baseResources.iec.RTE_AFRR_TEST_CONFIG, icon: <SignalCellularNoSimIcon /> },
      { name: "Tests AFRR RTE", urlParam: "/tests-afrr-rte", icon: <SsidChartIcon /> },
      { name: "Tests FCR RTE", urlParam: "/tests-fcr-rte", icon: <SsidChartIcon /> },
    ],
  },
  {
    listLabel: "RTE Communication",
    listItems: [{ name: "HealthChecks", urlParam: "/healthchecks", icon: <HealthAndSafetyIcon /> }],
  },
  {
    listLabel: "Optimizer",
    listItems: [
      { name: "Opti tasks", urlParam: baseResources.optimizer.TASKS, icon: <PsychologyIcon /> },
      { name: "Bid Settings", urlParam: baseResources.optimizer.BID_SETTINGS, icon: <PsychologyIcon /> },
      {
        name: "Certification Tests",
        urlParam: baseResources.optimizer.CERTIFICATIONS_TESTS,
        icon: <CheckCircleIcon />,
      },
      { name: "Certifications", urlParam: baseResources.optimizer.CERTIFICATIONS, icon: <WorkspacePremiumIcon /> },
      {
        name: "Groups Certifications",
        urlParam: baseResources.optimizer.GROUPS_CERTIFICATIONS,
        icon: <GroupWorkIcon />,
      },
    ],
  },
  {
    listLabel: "AFRR Engagement",
    listItems: [
      {
        name: "Visualizations",
        urlParam: "afrr-engagement",
        icon: <AssignmentTurnedInIcon />,
      },
      {
        name: "Engagement Points",
        urlParam: baseResources.afrrEngagement.POINT,
        icon: <RadioButtonCheckedIcon />,
      },
      {
        name: "Bid Time Series",
        urlParam: baseResources.afrrEngagement.BID_TIME_SERIES,
        icon: <BallotIcon />,
      },
    ],
  },
  {
    listLabel: "Finland",
    listItems: [
      {
        name: "Bids ECP",
        urlParam: baseResources.ecp.GATES,
        icon: <DoorBackIcon />,
      },
    ],
  },
  {
    listLabel: "Authentication",
    listItems: [{ name: "Users", urlParam: baseResources.users.USERS, icon: <AccountCircleIcon /> }],
  },
  {
    listLabel: "Configuration",
    listItems: [{ name: "Tasks & Webhooks", urlParam: "/config", icon: <MiscellaneousServicesIcon /> }],
  },
];

export const AppMenu = () => {
  return (
    <Menu>
      {itemsInfos.map(({ listLabel, listItems }) => (
        <SideBarListMenu key={listLabel} items={listItems} listLabel={listLabel} />
      ))}
    </Menu>
  );
};
