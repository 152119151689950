import { Card, CardHeader, Typography } from "@mui/material";
import axios from "axios";
import RteTmChart from "components/charts/tsoCommunication/rte/RteTmChart";
import RteTsChart from "components/charts/tsoCommunication/rte/RteTsChart";
import { baseResources } from "config_infos";
import { useCallback, useEffect, useState } from "react";
import { useRecordContext } from "react-admin";
import { RteConfigType } from "types";
import { Ti2Enum, Ti2TmEnum, Ti2TsEnum } from "types/enums";

const RteCommunicationGraphVisualizations = () => {
  const group = useRecordContext();
  const [configs, setConfigs] = useState<RteConfigType[]>([]);
  const canFetch = !!group && group.type === "rte_edr";

  const getConfigs = useCallback(async () => {
    const {
      data: { items },
    } = await axios({
      method: "get",
      url: `${process.env.REACT_APP_IEC_API_URL}/${baseResources.iec.CONFIGS}`,
      params: {
        page: 1,
        size: 100,
        code: group?.code_tm || "",
      },
    });
    return items;
  }, [group]);

  useEffect(() => {
    if (canFetch) {
      getConfigs()
        .then((configs) => {
          setConfigs(configs.filter((config: RteConfigType) => config.ti_2 !== Ti2Enum.N_RSFP));
        })
        .catch((error) => {
          console.log("error:", error);
        });
    }
  }, [canFetch, getConfigs]);

  if (!canFetch || configs.length === 0)
    return (
      <Typography variant="h6" align="center">
        No data to display
      </Typography>
    );

  const [tmIoas, tsIoas] = configs.reduce(
    (acc: [number[], { [key: string]: number }], config: RteConfigType) => {
      if (Object.values(Ti2TmEnum).includes(config.ti_2 as Ti2TmEnum)) {
        acc[0].push(config.ioa);
      }
      if (Object.values(Ti2TsEnum).includes(config.ti_2 as Ti2TsEnum)) {
        acc[1][config.ti_2] = config.ioa;
      }
      return acc;
    },
    [[], {}],
  );

  return (
    <Card sx={{ p: 4 }}>
      <CardHeader title="RTE Communication Graph Visualizations" />
      <RteTmChart ioas={tmIoas} />
      <RteTsChart ioas={tsIoas} />
    </Card>
  );
};

export default RteCommunicationGraphVisualizations;
