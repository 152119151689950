import { DataProvider, combineDataProviders } from "react-admin";

import { baseResources } from "config_infos";
import nwreServicesDataprovider from "../dataproviders/nwreServicesDataprovider";

// eslint-disable-next-line
const dataProviderServices = Object.entries(baseResources).map(([service, endpoints]) => {
  const { ENV_VAR, ...rest } = endpoints;
  return {
    dataProvider: nwreServicesDataprovider(ENV_VAR) as DataProvider<string>,
    resources: Object.values(rest) as string[],
  };
});

const combinedDataProvider = combineDataProviders((resource) => {
  const targetedDataProvider = dataProviderServices.find((dataProviderService) => {
    return dataProviderService.resources.includes(resource);
  });
  if (!targetedDataProvider) {
    throw new Error(`Resource ${resource} not found in dataProviderServices`);
  }
  return targetedDataProvider.dataProvider;
});

export default combinedDataProvider;
