import { Box, Grid, Typography } from "@mui/material";
import { DateField, Edit, Labeled, SimpleForm, TextField, TextInput, usePermissions } from "react-admin";

export const UserEdit = () => {
  const { permissions } = usePermissions();
  return permissions === "user" ? (
    <Typography>Vous n&apos;êtes pas autorisé à modifier un utilisateur</Typography>
  ) : (
    <Edit>
      <SimpleForm>
        <div>
          <Grid container width={{ xs: "100%", xl: 800 }} spacing={2}>
            <Grid item xs={12} md={8}>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="id">
                    <TextField source="id" fullWidth />
                  </Labeled>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="firstname" fullWidth />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="lastname" fullWidth />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="email" fullWidth />
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="role" fullWidth />
                </Box>

                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="created at">
                    <DateField source="created_at" fullWidth />
                  </Labeled>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="updated at">
                    <DateField source="updated_at" fullWidth />
                  </Labeled>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="deleted at">
                    <DateField source="deleted_at" fullWidth />
                  </Labeled>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  );
};
