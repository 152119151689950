import { Box, InputLabel, Stack, Typography } from "@mui/material";
import { BooleanInput } from "react-admin";

type ExplicitValuesBoolenInputProps = {
  label: string;
  falseLabel: string;
  trueLabel: string;
  source: string;
  defaultValue?: boolean;
};

const ExplicitValuesBoolenInput = ({
  label,
  falseLabel,
  trueLabel,
  source,
  defaultValue,
}: ExplicitValuesBoolenInputProps) => {
  return (
    <Box>
      <InputLabel>{label}</InputLabel>
      <Stack direction="row" alignItems="top">
        <Typography pt={1} pr={3}>
          {falseLabel}
        </Typography>
        <BooleanInput fullWidth source={source} label={false} defaultValue={defaultValue} />
        <Typography pt={1}>{trueLabel}</Typography>
      </Stack>
    </Box>
  );
};

export default ExplicitValuesBoolenInput;
