import { useState } from "react";

export default function useStatusAware(
  initialLoading = true,
  initialError: Error | null | [] = null,
  initialState: any = null,
) {
  const [loading, setLoading] = useState<boolean>(initialLoading);
  const [error, setError] = useState<any>(initialError);
  const [data, setData] = useState<any>(initialState);
  return {
    loading,
    setLoading,
    error,
    setError,
    data,
    setData,
  };
}
