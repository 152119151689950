import { Box } from "@mui/material";

export default function index({ children }: any) {
  return (
    <Box
      width={"100%"}
      display={"grid"}
      gridTemplateColumns={"repeat(4, 25%)"}
      gap={2}
      alignItems="center"
      justifyContent="center"
      p={2}
    >
      {children}
    </Box>
  );
}
