import { Loading } from "react-admin";
import { Error } from "../Error";
type Props = {
  error?: Error | null;
  loading?: boolean;
  children: any;
};

export default function StatusAware({ error, loading, children }: Props) {
  if (error) return <Error error={error} />;
  if (loading) return <Loading />;
  return children;
}
