import { Grid, Paper } from "@mui/material";
import { BooleanField, DateField, RecordContextProvider, TextField, useGetOne } from "react-admin";
import { useParams } from "react-router-dom";
import { baseResources } from "config_infos";
import ConfigWebhookUpdateForm from "./ConfigWebhookUpdateForm";
import StatusAware from "components/commons/statuses/StatusAware";
import CustomFieldLabel from "components/commons/fields/CustomFieldLabel";

type Props = {
  type?: string;
};

interface WebhookParams {
  service?: any;
  webhookId?: any;
}

export default function ConfigWebhookShow({ type }: Props) {
  const { service, webhookId }: WebhookParams = useParams();
  const resource = baseResources[service].WEBHOOKS;
  const { data, isLoading, error }: any = useGetOne(resource, {
    id: webhookId,
  });

  return (
    <StatusAware error={error} loading={isLoading}>
      <RecordContextProvider value={data}>
        <Grid container spacing={2} component={Paper} elevation={2} mt={2} p={2}>
          <Grid item xs={4}>
            <CustomFieldLabel source="id">
              <TextField source="id" />
            </CustomFieldLabel>
          </Grid>
          <Grid item xs={4}>
            <CustomFieldLabel source="created_at">
              <DateField source="created_at" />
            </CustomFieldLabel>
          </Grid>
          <Grid item xs={4}>
            <CustomFieldLabel source="updated_at">
              <DateField source="updated_at" />
            </CustomFieldLabel>
          </Grid>
          {type === "edit" ? (
            <Grid item xs={12}>
              <ConfigWebhookUpdateForm resource={resource} webhookId={webhookId} />
            </Grid>
          ) : (
            <>
              <Grid item xs={4}>
                <CustomFieldLabel source="active">
                  <BooleanField source="active" />
                </CustomFieldLabel>
              </Grid>
              <Grid item xs={4}>
                <CustomFieldLabel source="topic_action">
                  <TextField source="topic_action" />
                </CustomFieldLabel>
              </Grid>
              <Grid item xs={4}>
                <CustomFieldLabel source="topic_entity">
                  <TextField source="topic_entity" />
                </CustomFieldLabel>
              </Grid>
              <Grid item xs={4}>
                <CustomFieldLabel source="url">
                  <TextField source="url" />
                </CustomFieldLabel>
              </Grid>
            </>
          )}
        </Grid>
      </RecordContextProvider>
    </StatusAware>
  );
}
