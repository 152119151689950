import CreateIcon from "@mui/icons-material/Create";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Button } from "@mui/material";
import { useGetRecordId } from "react-admin";
import CustomLink from "../../fields/CustomLink";

type Props = {
  link: string;
  type?: string;
};

export default function CustomListButton({ link, type = "" }: Props) {
  const id = useGetRecordId();
  return (
    <CustomLink to={`${link}/${id}/${type}`}>
      {type === "show" ? (
        <Button startIcon={<RemoveRedEyeIcon />}>{"afficher"}</Button>
      ) : (
        <Button startIcon={<CreateIcon />}>{"modifier"}</Button>
      )}
    </CustomLink>
  );
}
