export type PerformanceChronicleResultType = {
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  power_mw: number;
  soc_min_pct: number;
  soc_max_pct: number;
  soc_bridage_max_pct: number;
  soc_bridage_min_pct: number;
  autonomy_max_bmin_mwh: number;
  autonomy_bmax_min_mwh: number;
  autonomy_bridage_inj_mwh: number;
  autonomy_max_min_inj_mwh: number;
  autonomy_max_min_sout_mwh: number;
  autonomy_bridage_sout_mwh: number;
  id: number;
  performance_chronicle_id: number;
};

export type PerformanceChronicleType = {
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  type: string;
  version: string;
  chronicle_filename: string;
  chronicle_result: string;
  comment: string;
  end_date: string;
  start_date: string;
  site_id: number;
  id: number;
  performance_chronicle_results: PerformanceChronicleResultType[];
  validated_at: string | null;
  failed_at: string | null;
};

export type ButtonActionType = "fail" | "validate";

export type ConfigsBySiteType = {
  [key: string]: { desired_configuration: any; reported_configuration: any };
};

export type ChoicesType = { id: string; name: string }[];

export enum ChronicleTypeEnum {
  Performance = "Performance",
  MSI = "MSI",
}

export enum JboxTypeEnum {
  Jbox_1 = 1,
  Jbox_2 = 2,
}

export type InitialFormValuesProps = {
  jboxType?: number;
  chronicleType?: string;
};

export type CustomRadioInputProps = {
  formData: InitialFormValuesProps;
  disabled: boolean;
};

export type GenerationTabFormProps = {
  initialFormValues: InitialFormValuesProps;
  resetForm: () => void;
};

export enum ColorStatusEnum {
  USED = "green",
  FAILED = "red",
  OBSOLETE = "gainboro",
  PENDING = "lightblue",
  AWAITING_APPROVAL = "transparent",
}

export enum ConfigurationStatusEnum {
  USED = "used",
  PENDING = "pending_deployment",
  OBSOLETE_OR_UNDEFINED = "obsolete_or_undefined",
}
