import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ConfigPeriodicTasksList from "./periodicTasks/ConfigPeriodicTasksList";
import { SERVICES_CONFIG_INFOS } from "config_infos";
import ConfigWebhooksList from "./webhooks/ConfigWebhooksList";

interface Service {
  id: number;
  name: string;
  param: string;
  taskEnum: string[] | null;
  endpoints: {
    webhook: string;
    periodicTasks: string;
  };
}

export function ConfigTabContent() {
  const { service: serviceParam } = useParams();
  const [selectedService, setSelectedService] = useState<Service>();

  useEffect(() => {
    setSelectedService(SERVICES_CONFIG_INFOS.find((service) => service.param === serviceParam));
  }, [serviceParam]);

  const webhookEndpoint = selectedService?.endpoints?.webhook || null;
  const taskEndpoint = selectedService?.endpoints?.periodicTasks || null;

  return (
    <Box pt={3}>
      {selectedService && (
        <Grid container direction={"column"} spacing={2}>
          {webhookEndpoint && (
            <Grid item>
              <ConfigWebhooksList endpoint={selectedService.endpoints.webhook} />
            </Grid>
          )}
          {taskEndpoint && (
            <Grid item>
              <ConfigPeriodicTasksList endpoint={selectedService.endpoints.periodicTasks} />
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
}
