import { Edit } from "react-admin";
import SitesBaseForm from "./SitesBaseForm";

export const SiteEdit = () => {
  return (
    <Edit>
      <SitesBaseForm isCreateForm={false} />
    </Edit>
  );
};
