import { SxProps } from "@mui/system";
import { hoursToSeconds, minutesToSeconds } from "date-fns";
import { Form, useListFilterContext } from "ra-core";
import { ChangeEvent, memo, useMemo } from "react";
import { TextInput, TextInputProps } from "react-admin";

export const CustomDateFilter = memo((props: FilterLiveSearchProps) => {
  const { filterValues, setFilters } = useListFilterContext();

  const { source = "q", timeOnly, ...rest } = props;

  const initialValues = useMemo(
    () => ({
      [source]: filterValues[source],
    }),
    [filterValues, source],
  );

  const convertTimeToSeconds = (time: string) => {
    const [hours, minutes, seconds] = time.split(":");
    return hoursToSeconds(+hours) + minutesToSeconds(+minutes) + +seconds;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target) {
      const value = event.target.value;
      setFilters(
        {
          ...filterValues,
          [source]: timeOnly ? convertTimeToSeconds(value) : value,
        },
        null,
      );
    } else {
      const { [source]: _, ...filters } = filterValues; // eslint-disable-line
      setFilters(filters, null);
    }
  };

  const onSubmit = () => undefined;

  const inputProps = timeOnly
    ? {
        type: "time",
        step: 1,
      }
    : {
        type: "datetime-local",
      };

  return (
    <Form defaultValues={initialValues} onSubmit={onSubmit}>
      <TextInput
        fullWidth
        resettable
        helperText={false}
        source={source}
        inputProps={inputProps}
        onChange={handleChange}
        size="small"
        InputLabelProps={{
          shrink: true,
        }}
        {...rest}
      />
    </Form>
  );
});

CustomDateFilter.displayName = "CustomDateFilter";

export interface FilterLiveSearchProps extends Omit<TextInputProps, "source"> {
  source?: string;
  sx?: SxProps;
  label?: string;
  fullWidth?: boolean;
  variant?: "filled" | "outlined";
  timeOnly?: boolean;
}
