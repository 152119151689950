import { MenuItem } from "@mui/material";

export const getSelectOptions = (initialList: string[]) =>
  initialList.map((item) => ({
    id: item,
    name: item,
  }));

export const getMuiSelectOptions = (initialList: string[]) => {
  return initialList.map((item, index) => (
    <MenuItem key={index} value={item}>
      {item}
    </MenuItem>
  ));
};
