import { Datagrid, ListContextProvider, NumberField, useList, useRecordContext } from "react-admin";

const PerformanceResultSubList = () => {
  const record = useRecordContext();
  const listContext = useList({ data: record?.performance_chronicle_results || [] });

  return (
    <ListContextProvider value={listContext}>
      <Datagrid
        bulkActionButtons={false}
        sx={{
          "& .RaDatagrid-table": {
            backgroundColor: "lightgrey",
          },
          "& .RaDatagrid-headerCell": {
            backgroundColor: "lightgrey",
          },
        }}
      >
        <NumberField source="power_mw" />
        <NumberField source="soc_min_pct" />
        <NumberField source="soc_max_pct" />
        <NumberField source="soc_clipping_max_pct" />
        <NumberField source="soc_clipping_min_pct" />
        <NumberField source="autonomy_max_cmin_mwh" />
        <NumberField source="autonomy_cmax_min_mwh" />
        <NumberField source="autonomy_clipping_injection_mwh" />
        <NumberField source="autonomy_max_min_injection_mwh" />
        <NumberField source="autonomy_max_min_withdrawal_mwh" />
        <NumberField source="autonomy_clipping_withdrawal_mwh" />
      </Datagrid>
    </ListContextProvider>
  );
};

export default PerformanceResultSubList;
