import { Typography } from "@mui/material";
import { Create, required, SelectInput, SimpleForm, TextInput, usePermissions } from "react-admin";

export const UserCreate = () => {
  const { permissions } = usePermissions();
  const ROLES = ["admin", "user"];
  const roleOptions = ROLES.map((role) => ({ id: role, name: role }));
  return permissions === "user" ? (
    <Typography>Vous n&apos;êtes pas autorisé à créer un utilisateur</Typography>
  ) : (
    <Create>
      <SimpleForm>
        <TextInput type="email" source="email" validate={required()} />
        <TextInput source="firstname" />
        <TextInput source="lastname" />
        <TextInput type="password" source="password" validate={required()} />
        <TextInput type="password" source="password_confirm" validate={required()} />
        <SelectInput source="role" choices={roleOptions} validate={required()} />
      </SimpleForm>
    </Create>
  );
};
