import { Alert, ListItem, ListItemIcon, ListItemText, List as MuiList, Typography } from "@mui/material";
import axios, { AxiosError } from "axios";
import { baseResources } from "config_infos";
import { getAxiosCustomErrorMessage } from "errors/handlers";
import useStatusAware from "hooks/useStatusAware";
import { useCallback } from "react";

import GenericStatusField from "components/commons/fields/GenericStatusFieldProps";
import ColorTag from "components/commons/tags/ColorTag";
import { format, parseISO } from "date-fns";
import {
  Datagrid,
  DateField,
  FunctionField,
  RaRecord,
  ReferenceField,
  TextField,
  useNotify,
  useRefresh,
} from "react-admin";
import { ButtonActionType, ColorStatusEnum, ConfigurationStatusEnum, PerformanceChronicleType } from "../types";
import PerformanceActionButtons from "./PerformanceActionButtons";
import PerformanceResultSubList from "./PerformanceResultSubList";

const PerformanceDatagrid = () => {
  const refresh = useRefresh();
  const notify = useNotify();
  const { loading, setLoading, error, setError } = useStatusAware(false);

  const handleClick = useCallback(
    (performance: PerformanceChronicleType) => async (action: ButtonActionType) => {
      const baseUrl = `${process.env.REACT_APP_SITE_API_URL}/${baseResources.sites.PERFORMANCE_CHRONICLES}`;
      setLoading(true);
      try {
        await axios({
          method: "post",
          url: `${baseUrl}${performance.id}/${action}/`,
        });
        notify(`${action} status: SUCCESS`, { type: "success" });
        refresh();
      } catch (error) {
        const message = getAxiosCustomErrorMessage(error as AxiosError);
        setError(message);
        notify(`${action} status: ERROR  |  ${message}`, { type: "error" });
      } finally {
        setLoading(false);
      }
    },
    [notify, refresh, setLoading, setError],
  );

  const getTagInfos = useCallback((currentPerformance: RaRecord) => {
    switch (currentPerformance.configuration_status) {
      case ConfigurationStatusEnum.USED:
        return { color: ColorStatusEnum.USED, text: "" };
      case ConfigurationStatusEnum.PENDING:
        return { color: ColorStatusEnum.PENDING, text: "" };
      case ConfigurationStatusEnum.OBSOLETE_OR_UNDEFINED:
        if (currentPerformance.failed_at) {
          return { color: ColorStatusEnum.FAILED, text: "" };
        }
        if (currentPerformance.validated_at) {
          return { color: ColorStatusEnum.OBSOLETE, text: "" };
        }
        return { color: ColorStatusEnum.AWAITING_APPROVAL, text: "" };
      default:
        return { color: ColorStatusEnum.AWAITING_APPROVAL, text: "" };
    }
  }, []);

  return (
    <>
      {error && (
        <Alert severity="error" sx={{ m: 2 }}>
          <Typography>{error}</Typography>
        </Alert>
      )}
      <MuiList
        sx={{
          display: "flex",
          direction: "row",
          justifyContent: "left",
          position: "sticky",
          top: 60, // Add this line to make the element stick at the top
          zIndex: 1000, // Optional: Ensure it stays on top of other content
          backgroundColor: "white", // Optional: Give it a background to avoid overlap issues
        }}
      >
        {Object.entries(ColorStatusEnum).map(([name, bgcolor], index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <ColorTag color={bgcolor} />
            </ListItemIcon>
            <ListItemText>{name}</ListItemText>
          </ListItem>
        ))}
      </MuiList>
      <Datagrid bulkActionButtons={false} expand={<PerformanceResultSubList />}>
        <PerformanceActionButtons handleClick={handleClick} loading={loading} />
        <ReferenceField source="site_id" reference={baseResources.sites.SITES} />
        <TextField source="id" label="Chronicle id" />
        <GenericStatusField source="status" sortable={false} tagInfoHandler={getTagInfos} />
        <FunctionField
          label="Last modified at"
          sortBy="failed_at, -validated_at, -created_at"
          render={(record: RaRecord) => {
            const failedAt = record?.failed_at ? format(parseISO(record.failed_at), "dd/MM/yyyy HH:mm:ss") : null;
            const validatedAt = record?.validated_at
              ? format(parseISO(record.validated_at), "dd/MM/yyyy HH:mm:ss")
              : null;
            return record ? failedAt || validatedAt || "N/A" : "";
          }}
        />
        <TextField source="type" />
        <TextField source="chronicle_filename" />
        <TextField source="chronicle_result" />
        <TextField source="comment" />
        <DateField locales={"fr-FR"} showTime source="start_date" />
        <DateField locales={"fr-FR"} showTime source="end_date" />
      </Datagrid>
    </>
  );
};

export default PerformanceDatagrid;
