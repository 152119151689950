import { Alert, AlertTitle } from "@mui/material";
import React from "react";

type Props = {
  severity?: "success" | "info" | "warning" | "error";
  title?: any;
  subTitle?: any;
};

export default function CustomAlert({ severity = "error", title, subTitle }: Props) {
  return (
    <Alert severity={severity}>
      <AlertTitle>{title}</AlertTitle>
      {subTitle}
    </Alert>
  );
}
