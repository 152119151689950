import { useState } from "react";

export default function useOpenClose() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevState) => !prevState);
  };
  return { open, handleOpen, handleClose, handleToggle };
}
