import {
  AutocompleteInput,
  ChipField,
  Datagrid,
  List,
  ReferenceArrayField,
  ReferenceInput,
  SelectInput,
  SingleFieldList,
  TextField,
  TextInput,
} from "react-admin";
import { baseResources } from "config_infos";
import { groupModeChoices, groupTypeChoices } from "enums/reactAdminChoices";

export const GroupList = () => {
  const groupFilters = [
    <ReferenceInput source="id__in" reference={baseResources.sites.GROUPS} key="id__in" label="name" alwaysOn>
      <AutocompleteInput
        optionText={({ id, name, code }: any) => {
          return `${id} - ${name} - ${code}`;
        }}
        label="name"
      />
    </ReferenceInput>,
    <TextInput label="Code" source="code" key="code" />,
    <TextInput label="Code DSO" source="code_dso" key="code_dso" />,
    <SelectInput label="Type" source="type" key="type" choices={groupTypeChoices} alwaysOn />,
    <SelectInput label="Mode" source="mode" key="mode" choices={groupModeChoices} />,
  ];

  return (
    <List filters={groupFilters}>
      <Datagrid rowClick="show">
        <TextField source="name" />
        <TextField source="mode" sortable={false} />
        <TextField source="code" sortable={false} />
        <TextField source="code_tm" sortable={false} />
        <TextField source="type" sortable={false} />
        <ReferenceArrayField source="site_ids" reference={baseResources.sites.SITES} sortable={false} label={"Sites"}>
          <SingleFieldList linkType="show">
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
      </Datagrid>
    </List>
  );
};
