import { Button } from "@mui/material";
import { useListContext, useResourceContext } from "react-admin";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import jsonExport from "jsonexport/dist";
import { useCallback } from "react";
import { downloadCSV, useDataProvider, useNotify } from "react-admin";
import { fetchAllRecords } from "./helpers";

type CustomExportButtonProps = {
  headers?: string[];
  includeDeleted?: boolean;
  filename?: string;
};

const CustomExportButton = ({ headers, filename, includeDeleted = false }: CustomExportButtonProps) => {
  const resource = useResourceContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { filterValues, sort } = useListContext();

  const handleClick = useCallback(async () => {
    try {
      const data = await fetchAllRecords(
        dataProvider,
        resource,
        {
          //TODO : add deleted filter once it is implemented in the backend
          filter: filterValues,
          pagination: { page: 1, perPage: 100 },
          sort,
        },
        includeDeleted,
      );

      jsonExport(data, { headers }, (err, csv) => {
        const now = new Date().toISOString();
        downloadCSV(csv, `${now}_${filename}` || `${now}_${resource}`);
      });
    } catch (error) {
      notify("Error fetching data for export", { type: "warning" });
    }
  }, [dataProvider, resource, notify, filterValues, sort, includeDeleted, headers, filename]);

  return (
    <Button variant="contained" onClick={handleClick} startIcon={<FileDownloadIcon />} size="small">
      Export
    </Button>
  );
};

export default CustomExportButton;
