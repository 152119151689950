import { addHours, formatISO } from "date-fns";
import { SortPayload, useDataProvider } from "react-admin";

import useDateRangePicker from "components/commons/pickers/DateRangePicker/hooks/useDateRangePicker";
import { subHours } from "date-fns";
import useStatusAware from "hooks/useStatusAware";
import { useCallback } from "react";
import { PlanningOrMaskType, PlanningType } from "types";

export default function usePlanningsHelpers(
  startDate: Date = subHours(new Date(), 24),
  endDate: Date = addHours(new Date(), 24),
) {
  const dataProvider = useDataProvider();

  const { data, setData, error, loading, setError, setLoading } = useStatusAware();
  const { dateRange, setDateRange } = useDateRangePicker(startDate, endDate);

  const fetchAllRessourcesWithinTimeRange = useCallback(
    async (
      resourceUrl: string,
      resourceSiteId?: number,
      explicitFilters?: any | undefined,
    ): Promise<PlanningOrMaskType[]> => {
      let itemsTotal = 0;
      let itemsCount = 0;
      let currentPage = 1;
      let result: PlanningOrMaskType[] = [];

      do {
        await dataProvider
          .getList(resourceUrl, {
            sort: { field: "start_date", order: "ASC" } as SortPayload,
            pagination: { page: currentPage, perPage: 100 },
            filter: explicitFilters || {
              site_id: resourceSiteId,
              end_date__gt: formatISO(dateRange.startDate),
              start_date__lt: formatISO(dateRange.endDate),
            },
          })
          .then(({ data, total }) => {
            itemsCount += data.length;
            itemsTotal = total ?? 0;
            currentPage++;
            result = result.concat(data);
          })
          .catch((error) => {
            setError(error);
            setLoading(false);
          });
      } while (itemsCount < itemsTotal);
      return result;
    },
    [dataProvider, dateRange, setError, setLoading],
  );

  const consolidatePlannings = useCallback((plannings: PlanningType[]): PlanningType[] => {
    // Use `reduce` to iterate over plannings and accumulate results in a single array
    return plannings.reduce((acc: PlanningType[], planning: PlanningType) => {
      const { start_date, end_date, mode } = planning;
      const cleanPlanning = {
        start_date,
        end_date,
        mode,
      };
      if (acc.length === 0) {
        // If the accumulator is empty, just add the first planning
        acc.push(cleanPlanning);
      } else {
        const lastPlanning = acc[acc.length - 1];
        if (lastPlanning.mode === planning.mode) {
          // If the current planning matches the last one in the accumulator in mode,
          // extend the last planning's end_date to the current planning's end_date
          lastPlanning.end_date = planning.end_date;
        } else {
          // Otherwise, push the current planning onto the accumulator
          acc.push(cleanPlanning);
        }
      }
      return acc; // Return the updated accumulator
    }, []); // Initial value of the accumulator is an empty array
  }, []);

  return {
    data,
    setData,
    dateRange,
    setDateRange,
    consolidatePlannings,
    fetchAllRessourcesWithinTimeRange,
    error,
    loading,
    setLoading,
  };
}
