import { Box } from "@mui/material";
import { Button, DateTimeInput, RadioButtonGroupInput, required, SimpleForm } from "react-admin";

type SelectRangePickerProps = {
  handleSubmit: (values: any) => void;
  choices?: { id: number; name: string }[] | null;
  defaultDate?: Date;
  defaultChoiceValue?: number;
};

export default function SelectRangePicker({
  handleSubmit,
  choices = null,
  defaultDate = new Date(),
  defaultChoiceValue = 1,
}: SelectRangePickerProps) {
  const radioChoices = choices || [
    { id: 1, name: "1 Hour" },
    { id: 2, name: "2 Hours" },
    { id: 3, name: "3 Hours" },
    { id: 6, name: "6 Hours" },
    { id: 12, name: "12 Hours" },
    { id: 24, name: "24 Hours" },
  ];

  return (
    <SimpleForm
      onSubmit={handleSubmit}
      toolbar={false}
      sx={{
        borderRadius: 2,
        border: "1px solid gainsboro",
        padding: 0,
        pl: 2,
        m: 2,
      }}
    >
      <Box display={"flex"} gap={2} alignItems={"center"}>
        <DateTimeInput validate={required()} source="startDate" defaultValue={defaultDate} />

        <RadioButtonGroupInput
          validate={required()}
          source="dateRange"
          helperText="Select the range of hours to display. WARNING: The bigger the range, the longer the loading time."
          choices={radioChoices}
          defaultValue={defaultChoiceValue}
          row={true}
        />
      </Box>
      <Button type="submit" label="search" variant="contained" size="large" sx={{ width: 300 }} />
    </SimpleForm>
  );
}
