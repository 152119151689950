import { Box } from "@mui/material";
import { BooleanField, DateField, NumberField, ReferenceField, Show, SimpleShowLayout, TextField } from "react-admin";
import CustomLabeledField from "components/commons/fields/CustomLabeledField";

export default function PlanningShow() {
  return (
    <Show>
      <SimpleShowLayout>
        <Box width={"100%"} display={"grid"} gridTemplateColumns={"repeat(3, 33%)"} gap={2} pr={3}>
          <CustomLabeledField source="id" FieldComponent={NumberField} />
          <CustomLabeledField source="received_at" FieldComponent={DateField} />
          <CustomLabeledField source="executed_at" FieldComponent={DateField} />
          <CustomLabeledField showTime source="start_date" FieldComponent={DateField} />
          <CustomLabeledField showTime source="end_date" FieldComponent={DateField} />
          <CustomLabeledField source="operator_id" FieldComponent={NumberField} />
          <CustomLabeledField source="sites" FieldComponent={ReferenceField} />
          <CustomLabeledField source="mode" FieldComponent={TextField} />
          <CustomLabeledField source="overidable" FieldComponent={BooleanField} />
          <CustomLabeledField source="fcr_engagement" FieldComponent={NumberField} />
          <CustomLabeledField source="afrr_engagement" FieldComponent={NumberField} />
          <CustomLabeledField source="initial_soc_restoration_power" FieldComponent={NumberField} />
          <CustomLabeledField source="soc_restoration_power" FieldComponent={NumberField} />
          <CustomLabeledField source="reactivity_delay" FieldComponent={NumberField} />
          <CustomLabeledField source="chronicle_filename" FieldComponent={TextField} />
          <CustomLabeledField source="created_at" FieldComponent={DateField} />
          <CustomLabeledField source="updated_at" FieldComponent={DateField} />
        </Box>
      </SimpleShowLayout>
    </Show>
  );
}
