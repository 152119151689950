import { Grid } from "@mui/material";
import { DateInput, Edit, SimpleForm } from "react-admin";

export default function CertificationsEdit() {
  return (
    <Edit>
      <SimpleForm>
        <Grid container>
          <Grid item xs={4}>
            <DateInput source="certif_name" />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
}
