import { TextField as MuiTextField } from "@mui/material";
import { Field } from "formik";

const CustomTextField = ({ field, error, errorMessage = null, ...props }: any) => {
  return (
    <MuiTextField
      sx={{
        width: "100%",
      }}
      error={error || errorMessage}
      helperText={errorMessage}
      inputProps={props?.inputProps}
      {...field}
      {...props}
    />
  );
};

export const FormikField = (props: any) => {
  return <Field component={CustomTextField} {...props} />;
};
