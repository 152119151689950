import { baseResources } from "config_infos";
import { planningModeChoices } from "enums/reactAdminChoices";
import { useMemo } from "react";
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  DateTimeInput,
  EditButton,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ShowButton,
  TextField,
} from "react-admin";

export default function PlanningsList() {
  const planningsFilters = useMemo(
    () => [
      <ReferenceInput key="site_id" source="site_id" reference={baseResources.sites.SITES} label="Site" />,
      <DateTimeInput key="start_date__lt" label="Start date before" source="start_date__lt" />,
      <DateTimeInput key="start_date__gt" label="Start date after" source="start_date__gt" />,
      <DateTimeInput key="end_date__lt" label="End date before" source="end_date__lt" />,
      <DateTimeInput key="end_date__gt" label="End date after" source="end_date__gt" />,
      <DateTimeInput key="received_at__lt" label="Received at before" source="received_at__lt" />,
      <DateTimeInput key="received_at__gt" label="Received at after" source="received_at__gt" />,
      <DateTimeInput key="executed_at__lt" label="Executed at before" source="executed_at__lt" />,
      <DateTimeInput key="executed_at__gt" label="Executed at after" source="executed_at__gt" />,
      <NumberInput key="fcr_engagement__lt" label="FCR engagement below" source="fcr_engagement__lt" />,
      <NumberInput key="fcr_engagement__gt" label="FCR engagement above" source="fcr_engagement__gt" />,
      <NumberInput
        key="initial_soc_restoration_power__lt"
        label="Initial SoC restoration power below"
        source="initial_soc_restoration_power__lt"
      />,
      <NumberInput
        key="initial_soc_restoration_power__gt"
        label="Initial SoC restoration power above"
        source="initial_soc_restoration_power__gt"
      />,
      <NumberInput
        key="soc_restoration_power__lt"
        label="SoC restoration power below"
        source="soc_restoration_power__lt"
      />,
      <NumberInput
        key="soc_restoration_power__gt"
        label="SoC restoration power above"
        source="soc_restoration_power__gt"
      />,
      <SelectInput key="mode" label="Mode" source="mode" choices={planningModeChoices} />,
      <BooleanInput key="overidable" label="Overridable" source="overidable" defaultValue={false} />,
    ],
    [],
  );

  return (
    <List filters={planningsFilters}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <DateField showTime source="received_at" />
        <TextField source="executed_at" />
        <DateField showTime source="start_date" />
        <DateField showTime source="end_date" />
        <TextField source="operator_id" />
        <ReferenceField source="site_id" reference="sites" link={"show"} />
        <TextField source="mode" />
        <BooleanField source="overidable" />
        <TextField source="fcr_engagement" />
        <TextField source="fcrn_engagement" />
        <TextField source="afrr_engagement" />
        <TextField source="initial_soc_restoration_power" />
        <TextField source="soc_restoration_power" />
        <TextField source="chronicle_filename" />
        <DateField showTime source="created_at" />
        <DateField showTime source="updated_at" />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
}
