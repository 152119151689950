import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HEALTHCHECKS_INFOS } from "config_infos";
import { IecHealthCheck } from "./iec/IecHealthCheck";

interface Service {
  id: number;
  name: string;
  param: string;
}

export const HealthChecksTabContent = () => {
  const { service: serviceParam } = useParams();
  const [selectedService, setSelectedService] = useState<Service>();

  const getHealthCheckContent = () => {
    switch (serviceParam) {
      case "iec":
        return <IecHealthCheck />;
      default:
        return null;
    }
  };

  useEffect(() => {
    setSelectedService(HEALTHCHECKS_INFOS.find((service) => service.param === serviceParam));
  }, [serviceParam]);

  return <Box>{selectedService && <Box> {getHealthCheckContent()} </Box>}</Box>;
};
