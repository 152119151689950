import { BooleanField, useRecordContext } from "react-admin";

type CustomBooleanFieldProps = {
  source: string;
};

const CustomBooleanField = ({ source }: CustomBooleanFieldProps) => {
  const record = useRecordContext();
  return <BooleanField sx={{ color: record && record[source] ? "green" : "red" }} source={source} />;
};

export default CustomBooleanField;
