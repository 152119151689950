import { useMemo } from "react";
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  DateInput,
  List,
  NumberField,
  NumberInput,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";
import { BIDS_PRODUCT_NAME, PRODUCT_TYPE } from "enums";
import { getSelectOptions } from "helpers";

export default function ResultsList() {
  const productTypeOptions = useMemo(() => getSelectOptions(PRODUCT_TYPE), []);
  const productNameOptions = useMemo(() => getSelectOptions(BIDS_PRODUCT_NAME), []);

  const resultsFilters = useMemo(
    () => [
      <DateInput key="delivery_date" label="Delivery date" source="delivery_date" />,
      <SelectInput key="product_name" label="product_name" source="product_name" choices={productNameOptions} />,
      <TextInput key="zone" label="zone" source="zone" />,
      <SelectInput key="product_type" label="product_type" source="product_type" choices={productTypeOptions} />,
      <NumberInput key="accepted_quantity" label="accepted_quantity" source="accepted_quantity" />,
      <NumberInput key="offered_quantity" label="offered_quantity" source="offered_quantity" />,
      <NumberInput key="accepted_price" label="accepted_price" source="accepted_price" />,
      <NumberInput key="offered_price" label="offered_price" source="offered_price" />,
      <BooleanInput key="indivisible" label="Indivisible" source="indivisible" defaultValue={false} />,
    ],
    [productNameOptions, productTypeOptions],
  );

  return (
    <List filters={resultsFilters}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <DateField source="delivery_date" />
        <TextField source="product_name" />
        <TextField source="zone" />
        <TextField source="product_type" />
        <NumberField source="accepted_quantity" />
        <NumberField source="offered_quantity" />
        <NumberField source="accepted_price" />
        <NumberField source="offered_price" />
        <BooleanField source="indivisible" />
      </Datagrid>
    </List>
  );
}
