import { Box, Grid } from "@mui/material";
import { DateField, Labeled, Show, SimpleForm, TextField } from "react-admin";

export const UserShow = () => {
  return (
    <Show>
      <SimpleForm>
        <div>
          <Grid container width={{ xs: "100%", xl: 800 }} spacing={2}>
            <Grid item xs={12} md={8}>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="id">
                    <TextField source="id" fullWidth />
                  </Labeled>
                </Box>

                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="firstname">
                    <TextField source="firstname" fullWidth />
                  </Labeled>
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="lastname">
                    <TextField source="lastname" fullWidth />
                  </Labeled>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="email">
                    <TextField source="email" fullWidth />
                  </Labeled>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="role">
                    <TextField source="role" fullWidth />
                  </Labeled>
                </Box>

                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="created at">
                    <DateField source="created_at" fullWidth />
                  </Labeled>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="updated at">
                    <DateField source="updated_at" fullWidth />
                  </Labeled>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="deleted at">
                    <DateField source="deleted_at" fullWidth />
                  </Labeled>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Show>
  );
};
