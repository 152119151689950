import { Box, ButtonGroup, Typography } from "@mui/material";
import { useState } from "react";

import CustomDialog from "components/commons/dialogs/CustomDialog";
import useOpenClose from "hooks/useOpenClose";
import { Button, useRecordContext } from "react-admin";
import { ButtonActionType, PerformanceChronicleType } from "../types";

const PerformanceActionButtons = ({
  handleClick,
  loading,
}: {
  handleClick: (performance: PerformanceChronicleType) => (action: ButtonActionType) => void;
  loading: boolean;
}) => {
  const record = useRecordContext();
  const { open, handleOpen, handleClose } = useOpenClose();
  const [confirm, setConfirm] = useState<ButtonActionType | null>(null);

  const handleButtonClick = handleClick(record as PerformanceChronicleType);

  const handleOpenDialog = (action: ButtonActionType) => {
    setConfirm(action);
    handleOpen();
  };

  const handleConfirm = () => {
    handleButtonClick(confirm as ButtonActionType);
    handleClose();
  };

  const isDisabled = loading || !!record.validated_at || !!record.failed_at;

  return (
    <Box display="grid" columnGap={2} gridTemplateColumns="1fr auto">
      <ButtonGroup variant="contained" fullWidth>
        <Button
          onClick={() => handleOpenDialog("validate")}
          disabled={isDisabled}
          label="Validate"
          sx={{ bgcolor: "limegreen", pl: 2, pr: 2 }}
        />
        <Button
          onClick={() => handleOpenDialog("fail")}
          disabled={isDisabled}
          label="Fail"
          sx={{ bgcolor: "red", pl: 2, pr: 2 }}
        />
      </ButtonGroup>
      <CustomDialog open={open} onClose={handleClose} title={confirm ? confirm.toUpperCase() : "Alert"}>
        <Box display="flex" flexDirection="column">
          <Typography>{`Are you sure you want to ${confirm} the performance id = ${record.id}?`}</Typography>
          <Button
            label="Confirm"
            onClick={handleConfirm}
            variant="contained"
            sx={{
              margin: "auto",
            }}
          />
        </Box>
      </CustomDialog>
    </Box>
  );
};

export default PerformanceActionButtons;
