export type SiteType = {
  deleted_at: null | string;
  dso: string;
  id: number;
  max_power_mw: number;
  name: string;
  rack_count: number;
  rte_code: string;
  useful_energy_at_1mw: number;
  available: boolean;
};

export type AfrrFormType = {
  Pprog_trial_1_8_racks: number;
  Pprog_trial_2_8_racks: number;
  Pprog_trial_1_10_racks: number;
  Pprog_trial_2_10_racks: number;
  afrr_engagement_default: number;
  sites: SiteType[];
  soc_trial_1: number;
  soc_trial_2: number;
  startingDate: Date;
  endingDate: Date;
};

export type PlanningFormType = {
  afrr_engagement: number | string;
  chronicle_filename: string;
  duration?: { hours?: number; minutes?: number };
  end_date: string;
  initial_soc_restoration_power: number | string;
  mode: string;
  name: string;
  overidable: boolean;
  role: string;
  start_date: string;
  target_soc: number | string;
  type: string;
  testsParticipation?: "test1" | "test2" | "all";
};

export type PlanningType = {
  start_date: string;
  end_date: string;
  mode: string;
  overidable: boolean;
  afrr_engagement?: number | string | null;
  initial_soc_restoration_power: number;
  chronicle_filename: string;
};

export type PlanningsBySiteType = {
  [key: number]: PlanningType[];
};

// type SiteRacksArray = SiteType[];

type PlanningsByRacksType = {
  [key: string]: PlanningsBySiteType;
};

type FormikValuesType = {
  plannings: PlanningsByRacksType;
  site8racks: SiteType;
  site10racks: SiteType;
};

export type FormikFieldsType = {
  values: FormikValuesType;
  handleChange: () => void;
};

export type GroupType = {
  code: string;
  code_dso: string | null;
  created_at: string;
  deleted_at: string | null;
  group_data: Record<string, any>;
  mode: string | null;
  name: string;
  site_ids: number[];
  sites: Record<string, any>[];
  updated_at: string;
};

export type RteAfrrPLanningSubFormType = {
  planningsName: string;
};

export type MaskType = {
  end_date: string;
  operating_mode: string;
  operator_id: number;
  site_id: number;
  start_date: string;
};

export enum MaskOperationModeEnum {
  fcr_standalone = "fcr_standalone",
  r2_standalone = "r2_standalone",
  r2_tests = "r2_tests",
  r2_chronicles = "r2_chronicles",
}
