import { FunctionField, useRecordContext } from "react-admin";
import { getHumanReadableCrontabInfos, getHumanReadableIntervalInfos } from "../helpers";

export default function ConditionalPeriodicTypeField(props: any) {
  const record = useRecordContext(props);
  return record ? (
    <FunctionField
      label="Interval details"
      render={(record: any) =>
        record.interval ? getHumanReadableIntervalInfos(record.interval) : getHumanReadableCrontabInfos(record.crontab)
      }
    />
  ) : null;
}
