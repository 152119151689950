import { baseResources } from "config_infos";
import {
  DateTimeInput,
  Edit,
  Labeled,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import { optimizerCertificationsTestsTypeChoices } from "../opti_enums";

export default function CertificationsTestsEdit() {
  return (
    <Edit
      mutationOptions={{
        meta: {
          method: "PATCH",
        },
      }}
    >
      <SimpleForm>
        <Labeled sx={{ mb: 2 }}>
          <TextField source="group_code" label={"Code EDP (rte_edp code)"} />
        </Labeled>
        <DateTimeInput source="start_date" fullWidth validate={required()} />
        <DateTimeInput source="end_date" fullWidth validate={required()} />
        <TextInput source="fcr_engagement" fullWidth validate={required()} />
        <TextInput source="afrr_engagement" fullWidth validate={required()} />
        <ReferenceInput source="certif_id" reference={baseResources.optimizer.CERTIFICATIONS}>
          <SelectInput optionText="certif_name" fullWidth validate={required()} label="Certification" />
        </ReferenceInput>
        <SelectInput
          source="test_type"
          choices={optimizerCertificationsTestsTypeChoices}
          fullWidth
          validate={required()}
        />
      </SimpleForm>
    </Edit>
  );
}
