export enum Ti2TmEnum {
  PC = "PC",
  SOC_EDR = "SOC.EDR",
  F_PROD = "F.PROD",
  P_PROD = "P.PROD",
  RET_RSFP = "RET.RSFP",
}

export enum Ti2TsEnum {
  RSFP = "RSFP",
  PART_FSM = "PART.FSM",
}

export enum Ti2Enum {
  PC = "PC",
  SOC_EDR = "SOC.EDR",
  F_PROD = "F.PROD",
  P_PROD = "P.PROD",
  RET_RSFP = "RET.RSFP",
  N_RSFP = "N.RSFP",
  RSFP = "RSFP",
  PART_FSM = "PART.FSM",
}
