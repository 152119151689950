import React from "react";
import { Labeled } from "react-admin";

interface Props {
  FieldComponent: React.ComponentType<any>;
  source: string;
  [rest: string]: any;
}

const index: React.FC<Props> = ({ FieldComponent, source, ...rest }) => {
  return (
    <Labeled source={source}>
      <FieldComponent source={source} {...rest} />
    </Labeled>
  );
};

export default index;
