import { useCallback, useEffect } from "react";
import useStatusAware from "./useStatusAware";

type PaginationType = {
  page: number;
  size: number;
};

type FetchFunctionType = (params: PaginationType) => Promise<{ items: any[]; total: number }>;

export default function useFetchPaginatedResource(
  fetchFunction: FetchFunctionType,
  size = 100,
  dependencies: any[] = [],
) {
  const { error, loading, data: items, setError, setLoading, setData: setItems } = useStatusAware(false, null, []);

  const fetchData = useCallback(
    async (size: number) => {
      let itemsTotal = 0;
      let itemsCount = 0;
      let currentPage = 1;
      let result: any[] = [];

      try {
        setLoading(true);
        do {
          const { items: fetchedItems, total } = await fetchFunction({
            page: currentPage,
            size,
          });
          itemsCount += fetchedItems.length;
          itemsTotal = total ?? 0;
          currentPage++;
          result = result.concat(fetchedItems);
        } while (itemsCount < itemsTotal);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
      return result;
    },
    [fetchFunction, setLoading, setError],
  );

  useEffect(() => {
    fetchData(size).then((data) => {
      setItems(data);
    });
  }, [setItems, size, ...dependencies]); // eslint-disable-line react-hooks/exhaustive-deps

  return { error, loading, items, fetchData };
}
