import { Alert, AlertTitle, Grid } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import { useCallback, useMemo, useState } from "react";
import { Loading, Title, useCreate } from "react-admin";
import { useNavigate } from "react-router-dom";
import TestsAfrrRteInitialForm from "./TestsAfrrRteInitialForm";
import TestsAfrrRtePlanningsForm from "./TestsAfrrRtePlanningsForm";
import { PlanningTypeEnum } from "./helpers";
import { generateInitialPlanningsValues } from "./tools";
import { AfrrFormType, PlanningFormType, PlanningType, PlanningsBySiteType, SiteType } from "./types";
import { baseResources } from "config_infos";
import { validationSchema } from "components/validations";

export default function TestsAfrrRtePlanningsContainer() {
  const [create, { isLoading, error }]: any = useCreate();
  const navigate = useNavigate();
  const [initialFormValues, setInitialFormValues] = useState<AfrrFormType | null>(null);

  const initialValues = useMemo(() => generateInitialPlanningsValues(initialFormValues), [initialFormValues]);

  const updatePlannings = useCallback(
    (formPlannings: PlanningFormType[]) =>
      formPlannings.map((planning) => {
        const {
          name, // eslint-disable-line
          testsParticipation, // eslint-disable-line
          duration, // eslint-disable-line
          role, // eslint-disable-line
          type,
          afrr_engagement,
          initial_soc_restoration_power,
          target_soc,
          ...rest
        } = planning;
        const isAfrr = type === PlanningTypeEnum.AFRR;
        return {
          ...rest,
          initial_soc_restoration_power: +initial_soc_restoration_power || 0,
          ...(isAfrr ? { afrr_engagement: +afrr_engagement || 0 } : {}),
          ...(!isAfrr ? { target_soc: +target_soc || 0 } : {}),
        };
      }),
    [],
  );

  const addPlanningsToSites = useCallback((sites: SiteType[], plannings: PlanningType[]): PlanningsBySiteType => {
    return sites.reduce((acc: PlanningsBySiteType, currentSite: SiteType) => {
      return currentSite.available
        ? {
            ...acc,
            [currentSite.id]: plannings,
          }
        : acc;
    }, {});
  }, []);

  const onSubmit = useCallback(
    (values: any) => {
      const {
        plannings: { plannings8Racks, plannings10Racks },
        site8racks,
        site10racks,
      } = values;

      const updatedPlannings8Racks = updatePlannings(plannings8Racks);
      const updatedPlannings10Racks = updatePlannings(plannings10Racks);
      const data = {
        ...addPlanningsToSites(site8racks, updatedPlannings8Racks),
        ...addPlanningsToSites(site10racks, updatedPlannings10Racks),
      };

      const resource = baseResources.plannings.PLANNINGS;
      create(
        resource,
        { data },
        {
          onSuccess: () => navigate(`/${resource}`),
        },
      );
    },
    [create, navigate, addPlanningsToSites, updatePlannings],
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema: validationSchema,
  });
  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
      <Title title="Tests RTE" />
      <Grid item padding={"16px 0"}>
        <TestsAfrrRteInitialForm setInitialValues={setInitialFormValues} />
      </Grid>
      {initialFormValues && (
        <FormikProvider value={formik}>
          <Grid item>
            {error && (
              <Alert severity="error">
                <AlertTitle>Error in the plannings</AlertTitle>
                <strong>{error.message}</strong>
              </Alert>
            )}
            {isLoading && <Loading />}
            <TestsAfrrRtePlanningsForm />
          </Grid>
        </FormikProvider>
      )}
    </Grid>
  );
}
