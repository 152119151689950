import { Link } from "react-router-dom";

export default function CustomLink(props: any) {
  const { children, ...rest } = props;
  return (
    <Link {...rest} style={{ textDecoration: "none" }}>
      {children}
    </Link>
  );
}
