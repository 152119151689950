import { SimpleForm, TextField, DateField, Labeled, Show, NumberField } from "react-admin";
import { Grid, Box } from "@mui/material";

export const PAShow = () => {
  return (
    <Show>
      <SimpleForm>
        <div>
          <Grid container width={{ xs: "100%", xl: 800 }} spacing={2}>
            <Grid item xs={12} md={8}>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="id">
                    <NumberField source="id" fullWidth />
                  </Labeled>
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="edp">
                    <NumberField source="edp" fullWidth />
                  </Labeled>
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="operator">
                    <TextField source="operator" fullWidth />
                  </Labeled>
                </Box>
              </Box>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="created_at">
                    <DateField source="created_at" fullWidth />
                  </Labeled>
                </Box>
                <Box flex={2} ml={{ xs: 0, sm: "0.5em" }} />
              </Box>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box display={{ xs: "block", sm: "flex" }}>
                  <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                    <Labeled source="start">
                      <DateField source="start" fullWidth />
                    </Labeled>
                  </Box>
                  <Box flex={2} ml={{ xs: 0, sm: "0.5em" }} />
                </Box>
                <Box display={{ xs: "block", sm: "flex" }}>
                  <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                    <Labeled source="end">
                      <DateField source="end" fullWidth />
                    </Labeled>
                  </Box>
                  <Box flex={2} ml={{ xs: 0, sm: "0.5em" }} />
                </Box>
              </Box>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="r1_h">
                    <NumberField source="r1_h" fullWidth />
                  </Labeled>
                </Box>
                <Box flex={2} ml={{ xs: 0, sm: "0.5em" }} />
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="r1_b">
                    <NumberField source="r1_b" fullWidth />
                  </Labeled>
                </Box>
                <Box flex={2} ml={{ xs: 0, sm: "0.5em" }} />
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="r2_h">
                    <NumberField source="r2_h" fullWidth />
                  </Labeled>
                </Box>
                <Box flex={2} ml={{ xs: 0, sm: "0.5em" }} />
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="r2_b">
                    <NumberField source="r2_b" fullWidth />
                  </Labeled>
                </Box>
                <Box flex={2} ml={{ xs: 0, sm: "0.5em" }} />
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <Labeled source="p0">
                    <NumberField source="p0" fullWidth />
                  </Labeled>
                </Box>
                <Box flex={2} ml={{ xs: 0, sm: "0.5em" }} />
              </Box>
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Show>
  );
};
