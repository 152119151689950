import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, IconButton } from "@mui/material";
import axios, { AxiosError } from "axios";
import { Error } from "components/commons/statuses/Error";
import { baseResources } from "config_infos";
import { PlanningModeEnum, SiteContryEnum } from "enums/globalEnums";
import {
  chronicleFilenamesChoices,
  finlandPlanningModeChoices,
  francePlanningModeChoices,
} from "enums/reactAdminChoices";
import { getAxiosCustomErrorMessage } from "errors/handlers";
import useStatusAware from "hooks/useStatusAware";
import { useCallback, useMemo } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Create,
  DateTimeInput,
  FormDataConsumer,
  FormDataConsumerRenderParams,
  Identifier,
  Loading,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  required,
  useGetOne,
  useNotify,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import { validatePlanning } from "./validateCreatePlanning";

interface PlanningCreateProps {
  defaultValues: object | null;
}

export default function PlanningCreate({ defaultValues = null }: PlanningCreateProps) {
  const navigate = useNavigate();
  const notify = useNotify();
  const { loading, setLoading, error, setError } = useStatusAware(false);

  const postPlannigs = useCallback(
    async (data: any) => {
      const url = `${process.env.REACT_APP_PLANNINGS_API_URL}/${baseResources.plannings.PLANNINGS}`;
      setLoading(true);
      try {
        await axios({
          method: "post",
          url,
          data,
        });
        notify(`Plannings posted successfully`, { type: "success" });
        setLoading(false);
        navigate(`/${baseResources.plannings.PLANNINGS}`);
      } catch (error) {
        setLoading(false);
        const message = getAxiosCustomErrorMessage(error as AxiosError);
        notify(`There was an error posting plannings: ${message}`, { type: "error" });
        setError(message);
      }
    },
    [notify, navigate, setLoading, setError],
  );

  const handleSubmit = useCallback(
    (values: any) => {
      const data = values.sites.reduce((acc: any, currentSite: any) => {
        const { site_id, plannings } = currentSite;
        return {
          ...acc,
          [site_id]: plannings.map((planning: any) => ({
            ...planning,
            site_id: site_id,
            fcr_engagement: planning?.fcr_engagement !== "" ? planning?.fcr_engagement : null,
            initial_soc_restoration_power:
              planning?.initial_soc_restoration_power !== "" ? planning?.initial_soc_restoration_power : null,
            soc_restoration_power: planning?.soc_restoration_power !== "" ? planning?.soc_restoration_power : null,
            reactivity_delay: planning?.reactivity_delay !== "" ? planning?.reactivity_delay : null,
            target_power_mw: planning?.target_power_mw !== "" ? planning?.target_power_mw : null,
            target_soc: planning?.target_soc !== "" ? planning?.target_soc : null,
          })),
        };
      }, {});

      postPlannigs(data);
    },
    [postPlannigs],
  );

  if (loading) return <Loading />;

  return (
    <Create>
      <SimpleForm
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
        mode="onBlur"
        // sanitizeEmptyValues
        validate={validatePlanning}
      >
        {error && <Error error={error} />}
        <ArrayInput source="sites">
          <SimpleFormIterator
            inline
            fullWidth
            disableReordering
            disableClear
            removeButton={
              <Button variant="contained" color={"error"} startIcon={<DeleteIcon />}>
                Supprimer le site
              </Button>
            }
            addButton={
              <Button variant="contained" startIcon={<AddCircleOutlineIcon />}>
                ajouter un site
              </Button>
            }
          >
            <ReferenceInput source="site_id" reference="sites" perPage={100}>
              <AutocompleteInput validate={required()} />
            </ReferenceInput>

            <ArrayInput source="plannings">
              <SimpleFormIterator
                inline
                disableReordering
                disableClear
                removeButton={
                  <IconButton>
                    <DeleteIcon color="error" />
                  </IconButton>
                }
                addButton={
                  <Button variant="contained" startIcon={<AddCircleOutlineIcon />}>
                    ajouter un Planning
                  </Button>
                }
                sx={{
                  p: 2,
                  mb: 2,
                  boxShadow:
                    "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);",
                }}
              >
                <DateTimeInput source="start_date" validate={[required()]} />
                <DateTimeInput source="end_date" validate={[required()]} />
                <FormDataConsumer>
                  {({ scopedFormData, getSource, formData }: FormDataConsumerRenderParams) => {
                    if (!getSource) return null;
                    const index = +getSource("").split(".")[1];
                    const siteId = formData.sites[index]?.site_id;
                    const mode = scopedFormData?.mode;
                    return (
                      <>
                        {siteId ? (
                          <CountryDependingMode siteId={siteId} source={getSource("mode")} />
                        ) : (
                          <SelectInput source={getSource("mode")} choices={[]} disabled />
                        )}
                        <ModeDependentFields mode={mode} getSource={getSource} />
                      </>
                    );
                  }}
                </FormDataConsumer>
                <BooleanInput source="overidable" defaultValue={false} label="Overridable" />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
}

const CountryDependingMode = ({ siteId, source }: { siteId: Identifier; source: string }) => {
  const { data, isLoading } = useGetOne(baseResources.sites.SITES, { id: siteId });

  const getModeDependingOnCountry = useCallback(() => {
    switch (data?.country) {
      case SiteContryEnum.France:
        return francePlanningModeChoices;
      case SiteContryEnum.Finland:
        return finlandPlanningModeChoices;
      default:
        return [];
    }
  }, [data]);

  if (isLoading) return <Loading />;
  return <SelectInput source={source} choices={getModeDependingOnCountry()} validate={required()} />;
};

enum PlanningFieldsEnum {
  fcr_engagement,
  target_soc,
  initial_soc_restoration_power,
  chronicle_filename,
  target_power_mw,
  fcrn_engagement,
  afrr_engagement,
  reactivity_delay,
  soc_restoration_power,
}

const ModeDependentFields = ({
  mode,
  getSource,
}: {
  mode: PlanningModeEnum;
  getSource: (source: string) => string;
}) => {
  // Add more fields if more markets or fields are added to the plannings
  const componentMapping: Record<PlanningFieldsEnum, JSX.Element> = useMemo(
    () => ({
      [PlanningFieldsEnum.fcr_engagement]: (
        <NumberInput source={getSource("fcr_engagement")} validate={[required()]} key={0} />
      ),
      [PlanningFieldsEnum.target_soc]: <NumberInput source={getSource("target_soc")} min={0} max={1} key={1} />,
      [PlanningFieldsEnum.initial_soc_restoration_power]: (
        <NumberInput source={getSource("initial_soc_restoration_power")} key={2} />
      ),
      [PlanningFieldsEnum.chronicle_filename]: (
        <SelectInput source={getSource("chronicle_filename")} choices={chronicleFilenamesChoices} key={3} />
      ),
      [PlanningFieldsEnum.target_power_mw]: (
        <NumberInput source={getSource("target_power_mw")} min={0} max={1000} key={4} />
      ),
      [PlanningFieldsEnum.fcrn_engagement]: (
        <NumberInput source={getSource("fcrn_engagement")} validate={[required()]} key={5} />
      ),
      [PlanningFieldsEnum.reactivity_delay]: <NumberInput source={"reactivity_delay"} min={0} max={10} key={6} />,
      [PlanningFieldsEnum.afrr_engagement]: (
        <NumberInput source={getSource("afrr_engagement")} validate={[required()]} key={7} />
      ),
      [PlanningFieldsEnum.soc_restoration_power]: <NumberInput source={getSource("soc_restoration_power")} key={8} />,
    }),
    [getSource],
  );

  // add, remove, switch inputs depending on the mode
  const modeComponentKeyMapping: Record<PlanningModeEnum, PlanningFieldsEnum[]> = useMemo(
    () => ({
      [PlanningModeEnum.fcr_rte_v2]: [PlanningFieldsEnum.fcr_engagement, PlanningFieldsEnum.soc_restoration_power],
      [PlanningModeEnum.fcr_rte_v2_no_reserve]: [
        PlanningFieldsEnum.fcr_engagement,
        PlanningFieldsEnum.soc_restoration_power,
      ],
      [PlanningModeEnum.power_chronicle]: [PlanningFieldsEnum.chronicle_filename, PlanningFieldsEnum.reactivity_delay],
      [PlanningModeEnum.power]: [PlanningFieldsEnum.target_power_mw],
      [PlanningModeEnum.soc_management]: [PlanningFieldsEnum.target_soc],
      [PlanningModeEnum.afrr_rte]: [PlanningFieldsEnum.afrr_engagement, PlanningFieldsEnum.soc_restoration_power],
      [PlanningModeEnum.afrr_fcr_rte]: [
        PlanningFieldsEnum.afrr_engagement,
        PlanningFieldsEnum.fcr_engagement,
        PlanningFieldsEnum.soc_restoration_power,
      ],
      [PlanningModeEnum.stop]: [],
      [PlanningModeEnum.fingrid_fcrn]: [PlanningFieldsEnum.fcrn_engagement, PlanningFieldsEnum.soc_restoration_power],
    }),
    [],
  );

  const componentKeys: PlanningFieldsEnum[] = modeComponentKeyMapping[mode] || [];

  return <>{componentKeys.map((key) => componentMapping[key])}</>;
};
