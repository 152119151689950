import { Grid } from "@mui/material";
import { DateField, Labeled, Show, SimpleShowLayout, TextField } from "react-admin";

export default function BidSettingsShow() {
  return (
    <Show>
      <SimpleShowLayout>
        <Grid item xs={4}>
          <Labeled source="market">
            <TextField source="market" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled source="country">
            <TextField source="country" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled source="start_date">
            <DateField source="start_date" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled source="end_date">
            <DateField source="end_date" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled source="delivery_date">
            <DateField source="delivery_date" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled source="min_price">
            <TextField source="min_price" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled source="max_price">
            <TextField source="max_price" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled source="price">
            <TextField source="price" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled source="min_quantity">
            <TextField source="min_quantity" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled source="max_quantity">
            <TextField source="max_quantity" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled source="offered_quantity">
            <TextField source="offered_quantity" />
          </Labeled>
        </Grid>
        <Grid item xs={4}>
          <Labeled source="direction">
            <TextField source="direction" />
          </Labeled>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
}
