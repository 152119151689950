import { add, format, isSameDay, parseISO } from "date-fns";

export const updateFollowingPlannings = (acc, currentPlanning) => {
  const prevPlanning = getPrevActivePlanning(acc);
  const start_date = prevPlanning
    ? new Date(getPrevActivePlanning(acc).end_date)
    : new Date(currentPlanning.start_date);
  return [
    ...acc,
    {
      ...currentPlanning,
      start_date,
      end_date: add(start_date, currentPlanning.duration),
    },
  ];
};

export const getPrevActivePlanning = (plannings) => {
  const activePlannings = plannings.filter((planning) => !planning.disabled);
  return activePlannings.at(-1);
};

const getTargetSoc = (target_soc_type, soc_trial_1_3, soc_trial_2_4) => {
  switch (target_soc_type) {
    case "soc_trial_1_3":
      return soc_trial_1_3;
    case "soc_trial_2_4":
      return soc_trial_2_4;
    case "notApplicable":
      return 0.5;
    default:
      return "";
  }
};

const getInitialSocRestorationPower = (
  initialSocRestorationPower,
  initial_soc_restoration_1_3,
  initial_soc_restoration_2_4,
) => {
  switch (initialSocRestorationPower) {
    case "initial_soc_restoration_1_3":
      return initial_soc_restoration_1_3;
    case "initial_soc_restoration_2_4":
      return initial_soc_restoration_2_4;
    default:
      return "";
  }
};

export const formatDate = (date) => {
  return format(date, "yyyy-MM-dd'T'HH:mm");
};

export const formatUStoFrDate = (usDate) => {
  const parsedDate = parseISO(usDate);
  return format(parsedDate, "dd/MM/yyyy HH:mm");
};

export const getPlanningBackgoundColor = (planning) => {
  if (planning.disabled) return "gainsboro";
  if (!planning.hasDatesOnSameDay) return "lightpink";
  return "transparent";
};

// -----------------------------------------------------------------

const baseValues = [
  {
    name: "planning_soc_1",
    mode: "soc_management",
    overidable: false,
    fcr_engagement: "",
    //fcr_engagement_default: "fcr_engagement_default",
    initial_soc_restoration_power: "",
    soc_restoration_power: "",
    chronicle_filename: "",
    reactivity_delay: 0,
    target_soc: "",
    // target_soc: 0.445,
    target_power_mw: "",
    target_soc_type: "soc_trial_1_3",
    duration: { minutes: 50 },
  },
  {
    name: "planning_trial_1b",
    mode: "fcr_rte_v2",
    overidable: false,
    //fcr_engagement: 1,
    fcr_engagement_default: "fcr_engagement_default",
    initial_soc_restoration_power: "initial_soc_restoration_1_3",
    soc_restoration_power: "",
    chronicle_filename: "essai_1bis.csv",
    reactivity_delay: 0,
    target_soc: "",
    // target_soc: 0,
    target_power_mw: "",
    target_soc_type: "",
    duration: { hours: 4, minutes: 10 },
  },
  {
    name: "planning_soc_1_2",
    mode: "soc_management",
    overidable: false,
    fcr_engagement: "",
    //fcr_engagement_default: "fcr_engagement_default",
    initial_soc_restoration_power: "",
    soc_restoration_power: "",
    chronicle_filename: "",
    reactivity_delay: 0,
    target_soc: "",
    // target_soc: 0.53,
    target_power_mw: "",
    target_soc_type: "soc_trial_2_4",
    duration: { minutes: 50 },
  },
  {
    name: "planning_trial_2",
    mode: "fcr_rte_v2_no_reserve",
    overidable: false,
    //fcr_engagement: 1,
    fcr_engagement_default: "fcr_engagement_default",
    initial_soc_restoration_power: "initial_soc_restoration_2_4",
    soc_restoration_power: "",
    chronicle_filename: "essai_2v2.csv",
    reactivity_delay: 0,
    target_soc: "",
    // target_soc: 0,
    target_power_mw: "",
    target_soc_type: "",
    duration: { minutes: 40 },
  },
  {
    name: "planning_soc_2_3",
    mode: "soc_management",
    overidable: false,
    fcr_engagement: "",
    //fcr_engagement_default: "fcr_engagement_default",
    initial_soc_restoration_power: "",
    soc_restoration_power: "",
    chronicle_filename: "",
    reactivity_delay: 0,
    target_soc: "",
    // target_soc: 0.445,
    target_power_mw: "",
    target_soc_type: "soc_trial_1_3",
    duration: { minutes: 50 },
  },
  {
    name: "planning_trial_3",
    mode: "fcr_rte_v2",
    overidable: false,
    //fcr_engagement: 1,
    fcr_engagement_default: "fcr_engagement_default",
    initial_soc_restoration_power: "initial_soc_restoration_1_3",
    soc_restoration_power: "",
    chronicle_filename: "essai_3v2.csv",
    reactivity_delay: 0,
    target_soc: "",
    // target_soc: 0,
    target_power_mw: "",
    target_soc_type: "",
    duration: { minutes: 10 },
  },
  {
    name: "planning_soc_3_4",
    mode: "soc_management",
    overidable: false,
    fcr_engagement: "",
    //fcr_engagement_default: "fcr_engagement_default",
    initial_soc_restoration_power: "",
    soc_restoration_power: "",
    chronicle_filename: "",
    reactivity_delay: 0,
    target_soc: "",
    // target_soc: 0.53,
    target_power_mw: "",
    target_soc_type: "soc_trial_2_4",
    duration: { minutes: 50 },
  },
  {
    name: "planning_trial_4",
    mode: "fcr_rte_v2",
    overidable: false,
    //fcr_engagement: 1,
    fcr_engagement_default: "fcr_engagement_default",
    initial_soc_restoration_power: "initial_soc_restoration_2_4",
    soc_restoration_power: "",
    chronicle_filename: "essai_4v2.csv",
    reactivity_delay: 0,
    target_soc: "",
    // target_soc: 0,
    target_power_mw: "",
    target_soc_type: "",
    duration: { minutes: 10 },
  },
  {
    name: "planning_soc_4_5",
    mode: "soc_management",
    overidable: false,
    fcr_engagement: "",
    //fcr_engagement_default: "fcr_engagement_default",
    initial_soc_restoration_power: "",
    soc_restoration_power: "",
    chronicle_filename: "",
    reactivity_delay: 0,
    /* target_soc: 0.5, */
    target_power_mw: "",
    target_soc_type: "notApplicable",
    duration: { minutes: 50 },
  },
  {
    name: "planning_trial_5",
    mode: "fcr_rte_v2",
    overidable: false,
    //fcr_engagement: 1,
    fcr_engagement_default: "fcr_engagement_default",
    initial_soc_restoration_power: "",
    soc_restoration_power: "",
    chronicle_filename: "essai_5.csv",
    reactivity_delay: 0,
    target_soc: "",
    target_power_mw: "",
    /* target_soc_type: "notApplicable", */
    duration: { minutes: 5 },
  },
  {
    name: "planning_soc_8h",
    mode: "soc_management",
    overidable: false,
    fcr_engagement: "",
    //fcr_engagement_default: "fcr_engagement_default",
    initial_soc_restoration_power: "",
    soc_restoration_power: "",
    chronicle_filename: "",
    reactivity_delay: 0,
    target_soc: 0.5,
    target_power_mw: "",
    target_soc_type: "notApplicable",
    duration: { minutes: 50 },
  },
  {
    name: "planning_trial_8h",
    mode: "fcr_rte_v2",
    overidable: false,
    //fcr_engagement: 1,
    fcr_engagement_default: "fcr_engagement_default",
    initial_soc_restoration_power: "",
    soc_restoration_power: "",
    chronicle_filename: "",
    reactivity_delay: 1500,
    target_soc: 0.5,
    target_power_mw: "",
    target_soc_type: "",
    duration: { hours: 8 },
  },
];

export const formatPlanningsDates = (plannings) =>
  plannings.map((planning) => {
    return {
      ...planning,
      start_date: formatDate(planning.start_date),
      end_date: formatDate(planning.end_date),
      hasDatesOnSameDay: isSameDay(planning.start_date, planning.end_date),
    };
  });

export const getInitialPlanningValues = (initFormValues) => {
  const initialValues = { plannings: null };
  if (initFormValues) {
    const {
      startingDate,
      soc_trial_1_3,
      soc_trial_2_4,
      fcr_engagement_default,
      initial_soc_restoration_1_3,
      initial_soc_restoration_2_4,
    } = initFormValues;
    const plannings = baseValues.reduce((acc, currentValues, index) => {
      const start_date = index === 0 ? startingDate : new Date(acc[index - 1].end_date);
      const end_date = add(start_date, currentValues.duration);
      return [
        ...acc,
        {
          ...currentValues,
          start_date,
          end_date,
          target_soc: getTargetSoc(currentValues.target_soc_type, soc_trial_1_3, soc_trial_2_4),
          initial_soc_restoration_power: getInitialSocRestorationPower(
            currentValues.initial_soc_restoration_power,
            initial_soc_restoration_1_3,
            initial_soc_restoration_2_4,
          ),
          disabled: false,
          fcr_engagement:
            currentValues.fcr_engagement !== undefined ? currentValues.fcr_engagement : fcr_engagement_default,
        },
      ];
    }, []);
    initialValues.plannings = formatPlanningsDates(plannings);
  }
  return initialValues;
};
