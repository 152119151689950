import { ChartData, ChartOptions, ChartTypeRegistry, LegendItem } from "chart.js";
import { format } from "date-fns";
import "chartjs-plugin-annotation";
import "chartjs-adapter-date-fns";
import { DateRangeType, MaskType, PlanningType } from "types";
import { PLANNING_MASK_MODE_ENUM, PLANNING_MODE_ENUM } from "enums";
import { useMemo } from "react";
import { generateChartDatasets, getColorByMode, maskModeColors, planningModeColors } from "../../helpers";

export function useChartConfig(plannings: PlanningType[], masks: MaskType[], dateRange: DateRangeType) {
  const datasets = useMemo(() => {
    const planningDatasets = generateChartDatasets(
      plannings,
      PLANNING_MODE_ENUM,
      getColorByMode(planningModeColors),
      "mode",
      "Plannings",
    );
    const maskDatasets = generateChartDatasets(
      masks,
      PLANNING_MASK_MODE_ENUM,
      getColorByMode(maskModeColors),
      "operating_mode",
      "Masks",
    );
    return [...maskDatasets, ...planningDatasets];
  }, [plannings, masks]);

  const options = useMemo(() => getChartOptions(dateRange), [dateRange]);

  const chartConfig = useMemo(() => {
    const labels = ["Masks", "Plannings"];
    const data = {
      labels,
      datasets,
    };
    return { data, options };
  }, [datasets, options]);

  return chartConfig;
}

function getChartOptions(dateRange: DateRangeType): ChartOptions<"bar"> {
  return {
    maintainAspectRatio: false,
    indexAxis: "y",
    scales: {
      x: {
        type: "time",
        time: {
          unit: "hour",
          displayFormats: {
            hour: "dd/MM/yyyy HH:mm",
          },
          tooltipFormat: "dd/MM/yyyy HH:mm",
        },
        min: dateRange.startDate.toISOString(),
        max: dateRange.endDate.toISOString(),
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
        title: {
          text: "Plannings and Masks",
          display: false,
        },
        fullSize: false,
        maxWidth: 100,
        labels: {
          filter: function (legendItem: LegendItem, chartData: ChartData<keyof ChartTypeRegistry>) {
            const targetedDataset = chartData.datasets.find((dataset: any) => dataset.label === legendItem.text);
            const is_present_in_dataset = targetedDataset && targetedDataset.data?.length > 0;
            return !!is_present_in_dataset;
          },
        },
      },
      tooltip: {
        position: "nearest",
        callbacks: {
          title: function (context: any) {
            if (context.length === 0) return "Planning";
            return context[0]?.dataset?.label || "";
          },
          label: function ({ raw }: any) {
            return raw.x.map((date: Date) => format(date, "dd/MM/yyyy HH:mm")).join(" - ");
          },
        },
      },
      annotation: {
        annotations: {
          line1: {
            type: "line",
            xMin: new Date().toISOString(),
            xMax: new Date().toISOString(),
            borderColor: "rgb(255, 99, 132)",
            borderWidth: 2,
          },
        },
      },
    },
  };
}
