import { Box, Card, CardContent, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import PointsBarChart from "components/charts/afrrEngagementChart/PointsBarChart";
import useBarChartPointsData from "components/charts/afrrEngagementChart/hooks/useBarChartPointsData";
import CustomDialog from "components/commons/dialogs/CustomDialog";
import DateRangePicker from "components/commons/pickers/DateRangePicker";
import { addHours, format } from "date-fns";
import useOpenClose from "hooks/useOpenClose";
import { useState } from "react";
import { Button, Loading } from "react-admin";
import EditCurrentBidTimeseries from "./EditCurrentBidTimeseries";
import PointsInRangeList from "./PointsInRangeList";

enum DialogType {
  points = "points",
  bids = "bids",
}

export default function AfrrEngagementVisualization() {
  const [dialogType, setDialogType] = useState<DialogType | null>(null);
  const { data, dateRange, setDateRange, isLoading, error, setTriggerRefresh } = useBarChartPointsData();
  const { open, handleOpen, handleClose: handleCloseBase } = useOpenClose();
  const [targetedPointStart, setTargetedPointStart] = useState<Date | null>(null);

  const handleOpenPointRange = (start_date: Date) => {
    setTargetedPointStart(start_date);
    setDialogType(DialogType.points);
    handleOpen();
  };

  const handleClose = () => {
    handleCloseBase();
    setTargetedPointStart(null);
    setDialogType(null);
  };

  const handleButtonClick = () => {
    setDialogType(DialogType.bids);
    handleOpen();
  };

  const getDialogInfos = () => {
    switch (dialogType) {
      case "points":
        return {
          dialogTitle: `Points in Range : ${format(targetedPointStart as Date, "dd/MM/yyyy HH:mm")} -> ${format(
            addHours(targetedPointStart as Date, 1),
            "dd/MM/yyyy HH:mm",
          )}`,
          dialogContent: (
            <PointsInRangeList filter={targetedPointStart ? { start_date: targetedPointStart.toISOString() } : {}} />
          ),
        };
      case "bids":
        return {
          dialogTitle: `Bid Timeseries : ${format(dateRange.startDate, "dd/MM/yyyy")}`,
          dialogContent: (
            <EditCurrentBidTimeseries
              date={dateRange.startDate}
              handleCloseDialog={handleClose}
              setTriggerRefresh={setTriggerRefresh}
            />
          ),
        };
      default:
        return { dialogTitle: "", dialogContent: null };
    }
  };

  const { dialogTitle, dialogContent } = getDialogInfos();

  return (
    <>
      <Box>
        <Box display={"flex"} justifyContent={"center"}>
          <Card
            elevation={4}
            sx={{
              marginTop: 2,
            }}
          >
            <CardContent
              sx={{
                padding: 0,
              }}
            >
              <Typography variant="h6" align="center">
                {"AFRR engagements Overview"}
              </Typography>
              <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} enforceMinMax={false} />
              <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={2}>
                <Typography variant="body1" align="center">
                  {`Current Date (corresponds to start date): ${format(dateRange.startDate, "dd/MM/yyyy")}`}
                </Typography>
                <Button label="view Gate details" variant="contained" onClick={handleButtonClick} />
              </Stack>
            </CardContent>
          </Card>
        </Box>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <PointsBarChart data={data} onBarClick={handleOpenPointRange} dateRange={dateRange} />
            <List dense={true} sx={{ display: "inline-flex" }}>
              <ListItem>
                <ListItemIcon>
                  <Box width={30} bgcolor={"red"} p={"1px"}></Box>
                </ListItemIcon>
                <ListItemText primary="Gichets 9h" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Box width={30} bgcolor={"green"} p={"1px"}></Box>
                </ListItemIcon>
                <ListItemText primary="Maintenant" />
              </ListItem>
            </List>
          </>
        )}
        {error && <Typography color="error">{error.message}</Typography>}
      </Box>
      <CustomDialog title={dialogTitle} open={open} onClose={handleClose}>
        {dialogContent}
      </CustomDialog>
    </>
  );
}
