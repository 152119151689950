import { Alert, AlertTitle } from "@mui/material";

type ErrorProps = {
  error: Error;
};

export const Error = (props: ErrorProps) => {
  const { error } = props;
  console.error(error);

  return (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      <>
        <strong>{error.name}</strong> - {error.message}
        {error.cause ?? ""}
        {error.stack ?? ""}
      </>
    </Alert>
  );
};
