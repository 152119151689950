import { baseResources } from "config_infos";
import { useEffect, useState } from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import axios from "axios";

import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";

const { REACT_APP_IEC_API_URL } = process.env;

interface Entity {
  code: string;
  status: string;
  message: string;
  timeTaken: string;
}

interface Service {
  status: string;
  totalTimeTaken: string;
  entities: Entity[];
}

export const IecHealthCheck = () => {
  const [data, setData] = useState<Service | null>(null);

  useEffect(() => {
    axios
      .get(`${REACT_APP_IEC_API_URL}/${baseResources.iec.HEALTH_CHECK}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Axios request error : ", error);
      });
  }, []);

  return (
    <div>
      <div style={{ marginBottom: "40px", marginTop: "20px" }}>
        {data && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small">
              <TableBody>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>Total Time Taken</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {data.status === "UNHEALTHY" ? (
                      <ClearIcon style={{ color: "red" }} />
                    ) : (
                      <CheckIcon style={{ color: "green" }} />
                    )}
                  </TableCell>
                  <TableCell>{data.totalTimeTaken}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left">Code</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">Message</TableCell>
                <TableCell align="left">Time Taken</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data ? (
                data.entities.map((entity, index) => (
                  <TableRow key={index}>
                    <TableCell align="left">{entity.code}</TableCell>
                    <TableCell align="left">
                      {entity.status === "UNHEALTHY" ? (
                        <ClearIcon style={{ color: "red" }} />
                      ) : (
                        <CheckIcon style={{ color: "green" }} />
                      )}
                    </TableCell>
                    <TableCell align="left">{entity.message}</TableCell>
                    <TableCell align="left">{entity.timeTaken}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Typography>Loading...</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
