import { useMemo } from "react";
import {
  Datagrid,
  DateField,
  DateInput,
  DateTimeInput,
  List,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
} from "react-admin";
import { optimizerCountryChoices, optimizerDirectionChoices, optimizerMarketChoices } from "../opti_enums";
export default function BidSettingsList() {
  const bidSettingsFilters = useMemo(
    () => [
      <SelectInput key="market" label="market" source="market" choices={optimizerMarketChoices} />,
      <SelectInput key="country" label="country" source="country" choices={optimizerCountryChoices} />,
      <DateTimeInput key="start_date__lt" label="Start date before" source="start_date__lt" />,
      <DateTimeInput key="start_date__gt" label="Start date after" source="start_date__gt" />,
      <DateTimeInput key="end_date__lt" label="End date before" source="end_date__lt" />,
      <DateTimeInput key="end_date__gt" label="End date after" source="end_date__gt" />,
      <DateTimeInput key="start_date" label="start_date" source="start_date" />,
      <DateTimeInput key="end_date" label="end_date" source="end_date" />,
      <DateInput key="delivery_date" label="delivery_date" source="delivery_date" />,
      <TextInput key="min_price" label="min_price" source="min_price" />,
      <TextInput key="max_price" label="max_price" source="max_price" />,
      <TextInput key="price" label="price" source="price" />,
      <TextInput key="min_quantity" label="min_quantity" source="min_quantity" />,
      <TextInput key="max_quantity" label="max_quantity" source="max_quantity" />,
      <TextInput key="offered_quantity" label="offered_quantity" source="offered_quantity" />,
      <SelectInput key="direction" label="direction" source="direction" choices={optimizerDirectionChoices} />,
    ],
    [],
  );
  return (
    <List filters={bidSettingsFilters}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="market" />
        <TextField source="country" />
        <TextField source="start_date" />
        <TextField source="end_date" />
        <DateField source="delivery_date" />
        <TextField source="min_price" />
        <TextField source="max_price" />
        <TextField source="price" />
        <TextField source="min_quantity" />
        <TextField source="max_quantity" />
        <TextField source="offered_quantity" />
        <TextField source="direction" />
        <ShowButton />
      </Datagrid>
    </List>
  );
}
