import { Create, ReferenceArrayInput, SimpleForm, TextInput } from "react-admin";

// change TextInput of this form
export const RTE_GateCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="id" />
      <ReferenceArrayInput source="rte_gate_ids" reference="rte_gates" />
      <TextInput source="rte_gate_data" />
    </SimpleForm>
  </Create>
);
