import { Datagrid, EditButton, EmailField, List, ShowButton, TextField, usePermissions } from "react-admin";

export const UsersList = () => {
  const { permissions } = usePermissions();
  return (
    <List>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="firstname" />
        <TextField source="lastname" />
        <EmailField source="email" />
        <TextField source="role" />
        {permissions === "admin" && <EditButton />}
        <ShowButton />
      </Datagrid>
    </List>
  );
};
