import { baseResources } from "config_infos";
import {
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  NumberField,
  NumberInput,
  ReferenceInput,
  TextField,
  TextInput,
} from "react-admin";

import { FilterProps } from "react-admin";

const BidTimeSeriesFilter = (props: FilterProps) => (
  <Filter {...props}>
    {props.children}
    <DateInput label="Start Date" source="start_date" />
    <DateInput label="Start Date (From)" source="start_date__gte" />
    <DateInput label="Start Date (To)" source="start_date__lte" />
    <DateInput label="End Date" source="end_date" />
    <DateInput label="End Date (From)" source="end_date__gte" />
    <DateInput label="End Date (To)" source="end_date__lte" />
    <TextInput label="Direction" source="direction" />
    <TextInput label="Divisible" source="divisible" />
    <TextInput label="Exclusivity Group" source="exclusivity_group" />
    <TextInput label="Symmetrical Feature" source="symetrical_feature" />
    <NumberInput label="Offered Quantity (Min)" source="offered_quantity__gte" />
    <NumberInput label="Offered Quantity (Max)" source="offered_quantity__lte" />
    <NumberInput label="Offered Price (Min)" source="offered_price__gte" />
    <NumberInput label="Offered Price (Max)" source="offered_price__lte" />
    <ReferenceInput
      label="Gate Document ID"
      source="gate_document_id"
      reference={baseResources.afrrEngagement.GATE_DOCUMENT}
    />
  </Filter>
);

export const AfrrEngagementBidTimeSeriesList = () => {
  return (
    <List
      filters={
        <BidTimeSeriesFilter>
          <></>
        </BidTimeSeriesFilter>
      }
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <DateField showTime locales={"fr-FR"} source="created_at" />
        <DateField showTime locales={"fr-FR"} source="updated_at" />
        <DateField showTime locales={"fr-FR"} source="start_date" />
        <DateField showTime locales={"fr-FR"} source="end_date" />
        <TextField source="direction" />
        <NumberField source="offered_quantity" />
        <NumberField source="offered_price" />
        <TextField source="divisible" />
        <TextField source="exclusivity_group" />
        <TextField source="symetrical_feature" />
        <TextField source="mrid" />
        <TextField source="gate_document_id" />
      </Datagrid>
    </List>
  );
};
