import { Alert, Box, Grid, Typography } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import axios from "axios";
import { add, getHours, getMinutes, getMonth, getYear } from "date-fns";
import { FocusEvent, useCallback, useState } from "react";
import {
  AutocompleteInput,
  CheckboxGroupInput,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  required,
  useDataProvider,
  useInput,
} from "react-admin";

import { stringify } from "query-string";
import { AfrrFormType, GroupType, MaskOperationModeEnum, MaskType, SiteType } from "./types";
import { baseResources } from "config_infos";
import { validateStartDate, validateTargetSocRange } from "components/validations";

const { REACT_APP_PLANNINGS_API_URL } = process.env;

const CustomDatetimePicker = (props: any) => {
  const { label, ...rest } = props;
  const { field, formState } = useInput(rest);

  const isError = formState && formState.errors && formState.errors[field?.name];

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box display={"flex"} flexDirection={"column"}>
        <DateTimePicker label={label} format="dd/MM/yyyy HH:mm" minutesStep={5} ampm={false} {...field} />

        {isError && (
          <Typography variant="caption" color={"error"}>
            {isError.message}
          </Typography>
        )}
      </Box>
    </LocalizationProvider>
  );
};

type TestsAfrrRteInitialFormProps = {
  setInitialValues: (values: AfrrFormType) => void;
};

export default function TestsAfrrRteInitialForm({ setInitialValues }: TestsAfrrRteInitialFormProps) {
  const dataProvider = useDataProvider();

  const [touchedFields, setTouchedFields] = useState<string[]>([]);
  const [errors, seterrors] = useState<string[]>([]);

  const handleSpecificFieldBlur = (fieldName: string) => {
    if (!touchedFields.includes(fieldName)) {
      setTouchedFields([...touchedFields, fieldName]);
    }
  };

  const handleFieldBlur = (event: FocusEvent<HTMLInputElement>) => {
    handleSpecificFieldBlur(event.target.name);
  };

  const fetchGroup = useCallback(
    async (group_id: number) => {
      try {
        const { data: fetchedGroupData } = await dataProvider.getOne(baseResources.sites.GROUPS, {
          id: group_id,
        });
        if (!fetchedGroupData) {
          throw new Error("Could not fetch group");
        }
        return fetchedGroupData;
      } catch (error: any) {
        seterrors((prevState) => [...prevState, `Error fetching group: ${error.message}`]);
        console.error("Error fetching group:", error);
      }
    },
    [dataProvider],
  );

  const isTestMasks = useCallback(
    (masks: MaskType[]): boolean => masks.every((mask) => mask.operating_mode === MaskOperationModeEnum.r2_chronicles),
    [],
  );

  const fetchMasks = useCallback(async (start_date: Date, end_date: Date, group: GroupType) => {
    try {
      const formattedStartDate = start_date.toISOString();
      const formattedEndDate = end_date.toISOString();
      const query = stringify({
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        site_id__in: group?.site_ids.toString(),
        page: 1,
        size: 100,
      });

      const { data: fetchedMaskData } = await axios.get(
        `${REACT_APP_PLANNINGS_API_URL}/${baseResources.plannings.PLANNING_MASKS}trimmed/?${query}`,
        { timeout: 10000 },
      );
      return fetchedMaskData;
    } catch (error: any) {
      seterrors((prevState) => [...prevState, `Error fetching masks: ${error.message}`]);
      console.error("Error fetching masks:", error);
    }
  }, []);

  const handleSubmit = async (values: any) => {
    setTouchedFields([]);
    seterrors([]);

    const { group_id, start_date: startDateLocale, ...rest } = values;
    const start_date: Date = new Date(
      Date.UTC(
        getYear(startDateLocale),
        getMonth(startDateLocale),
        startDateLocale.getDate(),
        getHours(startDateLocale),
        getMinutes(startDateLocale),
      ),
    );

    const end_date = add(start_date, { hours: 4 });
    const group = await fetchGroup(group_id);
    const { items: masks } = await fetchMasks(start_date, end_date, group);

    if (!masks.length || !isTestMasks(masks)) {
      seterrors((prevState) => [...prevState, `Not all masks are ${MaskOperationModeEnum.r2_chronicles} masks`]);
    } else {
      const initialPlanningsValues = {
        startingDate: start_date,
        endingDate: end_date,
        sites: group?.sites.map((site: SiteType) => ({
          ...site,
          available: true,
        })),
        ...rest,
      };
      setInitialValues(initialPlanningsValues);
    }
  };

  const commonProps = {
    validate: [required()],
    onBlur: handleFieldBlur,
    sx: { width: "100%" },
  };

  return (
    <SimpleForm onSubmit={handleSubmit}>
      <Box width={"100%"} display={"grid"} gridTemplateColumns={"repeat(3, 33%)"} gap={2}>
        <ReferenceInput
          label="EDP"
          source="group_id"
          reference={baseResources.sites.GROUPS}
          filter={{ type: "rte_edp" }}
        >
          <AutocompleteInput
            onBlur={() => handleSpecificFieldBlur("group_id")}
            validate={[required()]}
            optionText={(record) => `${record.id} ${record.name} ${record.code} > MODE: ${record.mode}`}
          />
        </ReferenceInput>

        <CustomDatetimePicker
          label={"Start date (utc) *"}
          source="start_date"
          onBlur={handleFieldBlur}
          validate={[required(), validateStartDate]}
        />
        <CheckboxGroupInput
          label="Tests to plan"
          source="plannedTests"
          choices={[
            { id: "test1", name: "Test AFRR 1" },
            { id: "test2", name: "Test AFRR 2" },
          ]}
          defaultValue={["test1", "test2"]}
          validate={[required()]}
        />
      </Box>
      <Typography variant="h6">SOC Management</Typography>
      <Box width={"100%"} display={"grid"} gridTemplateColumns={"repeat(3, 33%)"} gap={2}>
        <NumberInput
          label={"Target SOC 1"}
          source="soc_trial_1"
          onBlur={handleFieldBlur}
          validate={[required(), validateTargetSocRange]}
          inputProps={{ max: 1, min: 0 }}
        />
        <NumberInput
          label={"Target SOC 2"}
          source="soc_trial_2"
          onBlur={handleFieldBlur}
          validate={[required(), validateTargetSocRange]}
          inputProps={{ max: 1, min: 0 }}
        />
      </Box>
      <Grid container wrap="nowrap" spacing={4}>
        <Grid item xs={6}>
          <Typography variant="h6">AFRR Sites 8 Racks</Typography>
          <NumberInput label={"Initial SOC restoration power 1"} source="Pprog_trial_1_8_racks" {...commonProps} />
          <NumberInput label={"Initial SOC restoration power 2"} source="Pprog_trial_2_8_racks" {...commonProps} />
          <NumberInput label={"AFRR engagement"} source="afrr_engagement_8_racks" {...commonProps} />
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h6">AFRR Sites 10 Racks</Typography>
          <NumberInput label={"Initial SOC restoration power 1"} source="Pprog_trial_1_10_racks" {...commonProps} />
          <NumberInput label={"Initial SOC restoration power 2"} source="Pprog_trial_2_10_racks" {...commonProps} />
          <NumberInput label={"AFRR engagement"} source="afrr_engagement_10_racks" {...commonProps} />
        </Grid>
      </Grid>
      {errors.length > 0 &&
        errors.map((error, index) => (
          <Alert key={index} severity="error" sx={{ width: "100%" }}>
            <strong>{error}</strong>
          </Alert>
        ))}
      {touchedFields.length > 0 && (
        <Alert severity="warning" sx={{ width: "100%" }}>
          <strong>The changes need to be validated to be taken into account</strong>
        </Alert>
      )}
    </SimpleForm>
  );
}
