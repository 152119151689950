import { addDays, endOfDay, startOfDay } from "date-fns";
import { useState } from "react";

export default function useDateRangePicker(
  startDate: Date = startOfDay(new Date()),
  endDate: Date = addDays(endOfDay(new Date()), 2),
) {
  const [dateRange, setDateRange] = useState({
    startDate,
    endDate,
  });

  return { dateRange, setDateRange };
}
