import { Show, SimpleShowLayout, TextField, DateField } from "react-admin";

const GateShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <TextField source="message_id" label="Message ID" />
      <DateField source="delivery_date" label="Delivery Date" />
    </SimpleShowLayout>
  </Show>
);

export default GateShow;
