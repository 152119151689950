import { Identifier } from "react-admin";

export enum AfrrBidDirectionEnum {
  UPWARD = "UPWARD",
  DOWNWARD = "DOWNWARD",
  SYMETRICAL = "SYMETRICAL",
}

export enum AfrrBidStatusEnum {
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  NOT_SENT = "NOT_SENT",
  PENDING = "PENDING",
  PARTIALLY_ACCEPTED = "PARTIALLY_ACCEPTED",
  // OBSOLETE = "OBSOLETE",
  REFUSED = "REFUSED",
}

export type BidTimeseriesType = {
  start_date: Date | string;
  end_date: Date | string;
  direction: AfrrBidDirectionEnum;
  offered_quantity: number;
  offered_price: number;
  id?: Identifier;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
  divisible?: string;
  exclusivity_group?: string;
  symetrical_feature?: string;
  mrid?: string;
  gate_document_id?: number;
};

export type PointType = {
  end_date: Date | string;
  start_date: Date | string;
  x: Date | string;
  y: number;
  accepted_price?: number;
  accepted_quantity?: number;
  bid_time_series_id?: number;
  created_at?: Date | string;
  deleted_at?: Date | string;
  updated_at?: Date | string;
  direction?: AfrrBidDirectionEnum;
  id?: Identifier;
  offered_price: number;
  offered_quantity: number;
  position?: number;
  status: AfrrBidStatusEnum;
};

export type AfrrGateType = {
  created_at?: Date | string;
  updated_at?: Date | string;
  deleted_at?: Date | string;
  revision_number?: number;
  delivery_date?: Date | string;
  deposit_date?: Date | string;
  xml_file_link?: string;
  id?: Identifier;
  mrid?: string;
  status?: AfrrBidStatusEnum;
  bid_time_series?: BidTimeseriesType[];
};
