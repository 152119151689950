import { Grid } from "@mui/material";
import { DateField, Show, SimpleShowLayout, TextField, useResourceContext } from "react-admin";
import CustomFieldLabel from "components/commons/fields/CustomFieldLabel";
import { baseResources } from "config_infos";
export default function BidsShow() {
  const resource = useResourceContext();
  const isManualBids = baseResources.regelleistung.MANUAL_BIDS === resource;
  return (
    <Show>
      <SimpleShowLayout>
        <Grid container spacing={2}>
          {!isManualBids && (
            <Grid item xs={4}>
              <CustomFieldLabel source="regel_bid_id">
                <TextField source="regel_bid_id" />
              </CustomFieldLabel>
            </Grid>
          )}
          <Grid item xs={4}>
            <CustomFieldLabel source="product_name">
              <TextField source="product_name" />
            </CustomFieldLabel>
          </Grid>
          <Grid item xs={4}>
            <CustomFieldLabel source="status">
              <TextField source="status" />
            </CustomFieldLabel>
          </Grid>
          <Grid item xs={4}>
            <CustomFieldLabel source="deposit_operator">
              <TextField source="deposit_operator" />
            </CustomFieldLabel>
          </Grid>
          <Grid item xs={4}>
            <CustomFieldLabel source="currency">
              <TextField source="currency" />
            </CustomFieldLabel>
          </Grid>
          <Grid item xs={4}>
            <CustomFieldLabel source="delivery_date">
              <DateField source="delivery_date" />
            </CustomFieldLabel>
          </Grid>
          <Grid item xs={4}>
            <CustomFieldLabel source="accepted_quantity">
              <TextField source="accepted_quantity" />
            </CustomFieldLabel>
          </Grid>
          <Grid item xs={4}>
            <CustomFieldLabel source="offered_quantity">
              <TextField source="offered_quantity" />
            </CustomFieldLabel>
          </Grid>
          <Grid item xs={4}>
            <CustomFieldLabel source="accepted_price">
              <TextField source="accepted_price" />
            </CustomFieldLabel>
          </Grid>
          <Grid item xs={4}>
            <CustomFieldLabel source="offered_price">
              <TextField source="offered_price" />
            </CustomFieldLabel>
          </Grid>
          <Grid item xs={4}>
            <CustomFieldLabel source="country_code">
              <TextField source="country_code" />
            </CustomFieldLabel>
          </Grid>
          <Grid item xs={4}>
            <CustomFieldLabel source="tso">
              <TextField source="tso" />
            </CustomFieldLabel>
          </Grid>
          <Grid item xs={4}>
            <CustomFieldLabel source="market">
              <TextField source="market" />
            </CustomFieldLabel>
          </Grid>
          <Grid item xs={4}>
            <CustomFieldLabel source="zone">
              <TextField source="zone" />
            </CustomFieldLabel>
          </Grid>
          <Grid item xs={4}>
            <CustomFieldLabel source="id">
              <TextField source="id" />
            </CustomFieldLabel>
          </Grid>
          <Grid item xs={4}>
            <CustomFieldLabel source="deleted_at">
              <TextField source="deleted_at" />
            </CustomFieldLabel>
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
}
